import React from 'react';
import LegalPageHeader from './LegalPageHeader';

import html from './privacy-policy';

const Privacy = () => {
  return (
    <div className="legal-page privacy">
      <LegalPageHeader title={'Privacy Policy'} />
      <div className="text-content" dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  );
};

export default Privacy;
