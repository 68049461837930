import { FETCH_WITH_AUTH } from '../constants/actionTypes';

const fetchMiddleware = fetchImplementation => store => next => (action) => {
  if (action.type === FETCH_WITH_AUTH) {
    const { url, params } = action;
    const { session } = store.getState();
    if (session.currentUser) {
      const { token } = session.currentUser;
      const paramsWithAuth = {
        ...params,
        headers: {
          ...params.headers,
          'Authorization': `Bearer ${token}`,
        },
      };
      return fetchImplementation(url, paramsWithAuth);
    }
    // eslint-disable-next-line no-console
    console.error(`attempted an authenticated fetch without logged in user for route ${url}`);
  }
  return next(action);
};

export default fetchMiddleware;
