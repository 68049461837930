import React from 'react';
import PropTypes from 'prop-types';

import SentryErrorBoundary from './SentryErrorBoundary';

export const ClientSideError = ({ errCallback }) => (
  <div className="error-page-500">
    <div className="message">
      <span className="header">Oops...</span>
      <p>
        ...something went wrong on our end. If you&apos;d like to help, tell us what happened below.
      </p>
    </div>
    <button className="submit-btn" type="button" onClick={errCallback}>
      report error
    </button>
  </div>
);

ClientSideError.propTypes = {
  errCallback: PropTypes.func.isRequired,
};


/**
 * For handling client side exceptions, etc.
 */
const RootErrorBoundary = ({ children }) => {
  return (
    <SentryErrorBoundary fallbackComponent={ClientSideError}>
      {children}
    </SentryErrorBoundary>
  );
};

RootErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RootErrorBoundary;
