import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import SearchContainer from '../search/SearchContainer';
import SearchToggle from '../search/SearchToggle';

const NavButtons = ({ currentUser }) => {
  return (
    <nav className="main-nav">
      <ul>
        <li><NavLink className="nav-button" exact to="/">Feed</NavLink></li>
        <li><NavLink className="nav-button" to="/create">Create</NavLink></li>
        <li><NavLink className="nav-button" to={`/user/${currentUser.username}`}>Profile</NavLink></li>
        <SearchToggle />
      </ul>
      <SearchContainer />
    </nav>
  );
};

NavButtons.propTypes = {
  currentUser: PropTypes.shape({
    username: PropTypes.string,
  }).isRequired,
};

export default NavButtons;
