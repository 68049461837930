import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import PostHeader from './PostHeader';
import PostStats from './PostStats';
import PostCaption from './PostCaption';
import PostComments from './PostComments';
import PostImageDetailToggler from './PostImageDetailToggler';


/**
 * This is supposed to be a publically viewable page,
 * at least for events that are not private, so this is
 * why we see a lot of checks like "if (props.currentUser)..."
 */

const MIN_DETAIL_HEIGHT_PX = 290;

const Post = (props) => {
  const { id, post, attendees, likers, currUserLiked, currUserAttending,
    openInviteModal, closeInviteModal } = props;

  const [commentInputOpen, setCommentInputOpen] = useState(false);
  const [detailViewOpen, toggleDetailView] = useState(false);
  const [isInviting, setIsInviting] = useState(false);
  const [imageHeightPx, setImageHeightPx] = useState(MIN_DETAIL_HEIGHT_PX);

  const postImageRef = useRef(null);

  const handleToggleDetails = () => {
    toggleDetailView(!detailViewOpen);
  };

  // get additional post data only if the session is authenticated
  useEffect(() => (props.currentUser ? props.init(props.id) : undefined), []);

  const redirectToHomepage = () => props.history.push('/');

  // TODO refactor the following 3 functions to share a higher order function.
  const updateStar = newVal => (
    props.currentUser
      ? props.updatePostStar(props.id, props.currentUser.id, newVal)
      : redirectToHomepage()
  );

  const updateAttend = newVal => (
    props.currentUser
      ? props.updateAttendance(props.id, props.currentUser.id, newVal)
      : redirectToHomepage()
  );

  const openCommentInputHandler = newVal => (
    props.currentUser
      ? setCommentInputOpen(newVal)
      : redirectToHomepage()
  );

  const addCommentHandler = (numComments, numCommentsBelowPost) => {
    setCommentInputOpen(false);
    if (numComments >= numCommentsBelowPost) {
      props.history.push(`/event/${id}/comments`);
    }
  };

  const updateFollow = newVal => (
    props.currentUser
      ? props.updateFollow(props.post.username, newVal)
      : redirectToHomepage()
  );

  const numLikes = likers ? Object.keys(likers).length : 0;
  const numAttendees = attendees ? Object.keys(attendees).length : 0;

  return (
    <div>
     
      <PostImageDetailToggler
        post={post}
        postImageRef={postImageRef}
        attendees={attendees}
        isInviting={isInviting}
        setIsInviting={setIsInviting}
        userIsLoggedIn={!!props.currentUser}
        userIsAuthor={props.currentUser && props.currentUser.username === props.post.username}
        postIsStarred={currUserLiked}
        userIsAttending={currUserAttending}
        updateStar={val => updateStar(val)}
        updateAttending={val => updateAttend(val)}
        updateFollow={val => updateFollow(val)}
        imageHeightPx={imageHeightPx}
        setModalIsOpen={(postId) => {
          if (postId) openInviteModal(postId);
          else closeInviteModal();
        }}
        detailViewOpen={detailViewOpen}
        toggleDetailView={handleToggleDetails}
      />

      <div className="post-caption">
        <div className="text-small">
            <span className="text-gray uppercase">Hosted by</span> { post.username }
        </div>
        <button className="detail-toggle-btn" onClick={handleToggleDetails}>
            <span className="text-gray uppercase text-small">Event Details</span>
        </button>
      </div>
    
      {/* @@TODO Make these puppies work */}
      <div className="btn-options">
        <button className="uppercase">Save</button>
        <button className="uppercase">Share</button>
        <button className="uppercase">Attend</button>
      </div>
      
    </div>
  );
};

Post.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number,
    token: PropTypes.string,
    username: PropTypes.string,
  }),

  post: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    location: PropTypes.string,
    username: PropTypes.string,
    description: PropTypes.string,
    isPrivate: PropTypes.bool,
    isAccessible: PropTypes.bool,
    ageRestriction: PropTypes.string,
    eventTimestamp: PropTypes.string,
    publicUrl: PropTypes.string,
    thumbPublicUrl: PropTypes.string,
  }).isRequired,

  attendees: PropTypes.arrayOf(
    PropTypes.shape({
      username: PropTypes.string,
    }),
  ).isRequired,

  likers: PropTypes.arrayOf(
    PropTypes.shape({
      username: PropTypes.string,
    }),
  ).isRequired,

  id: PropTypes.number.isRequired,
  currUserAttending: PropTypes.bool,
  currUserLiked: PropTypes.bool,

  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,

  updatePostStar: PropTypes.func.isRequired,
  updateAttendance: PropTypes.func.isRequired,
  openInviteModal: PropTypes.func.isRequired,
  closeInviteModal: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  updateFollow: PropTypes.func.isRequired,
  init: PropTypes.func.isRequired,
};

Post.defaultProps = {
  currUserAttending: false,
  currUserLiked: false,
  currentUser: null,
};

export default withRouter(Post);
