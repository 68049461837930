import { connect } from 'react-redux';

import LoginForm from './LoginForm';
import { loginUser } from '@happenings/components/session';


const mapStateToProps = state => ({
  loggedIn: !!state.session.currentUser,
});

const mapDispatchToProps = dispatch => ({
  login: (username, password) => dispatch(loginUser(username, password)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginForm);
