import React from 'react';
import PropTypes from 'prop-types';

import ProfilePostsContainer from './ProfilePostsContainer';
import ProfileHeader from './ProfileHeader';
import LoadingIcon from '../common/LoadingIcon';

/**
 * Publically viewable page, don't need to be signed in. Will redirect to
 * public landing if 'like' or 'follow' is clicked with no signed in user.
 */

class ProfilePage extends React.Component {
  componentDidMount() {
    this.props.initUserPage(this.props.username);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.username
        !== this.props.match.params.username) {
      this.props.initUserPage(nextProps.match.params.username);
    }
  }

  render() {
    // TODO: abstact this loading away into HOC or use render props
    const fallback = this.props.notFound ? null : <LoadingIcon />;
    return this.props.loaded ? (
      <div>
        <div className="profile-container">
          <ProfileHeader username={this.props.username} />
          <ProfilePostsContainer username={this.props.username} />
        </div>
      </div>
    ) : fallback;
  }
}

ProfilePage.propTypes = {
  username: PropTypes.string.isRequired,
  loaded: PropTypes.bool.isRequired,
  initUserPage: PropTypes.func.isRequired,

  match: PropTypes.shape({
    params: PropTypes.shape({
      username: PropTypes.string,
    }),
  }).isRequired,

  notFound: PropTypes.bool.isRequired,
};

export default ProfilePage;
