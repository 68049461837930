import React from 'react';
import PropTypes from 'prop-types';
import CopyEventLink from './CopyEventLink';

// functionalities:
// 1 - copy post URL
// 2 - send invite(s)
// 3 - unfollow
// 4 - report event

const PostOptionsModal = ({ currentUser, postId,
  inviteModalOpen,
  openInviteModal,
  closeInviteModal }) => {
  const reportEvent = () => {
    console.error('have not implemented this yet!');
  };
  const toggleInviteModal = () => {
    if (inviteModalOpen) {
      closeInviteModal();
    } else {
      openInviteModal();
    }
  };
  return (
    <div>
      <div role="button" className="modal-option border-bottom">
        <CopyEventLink postId={postId} />
      </div>
      <div role="button" className="modal-option border-bottom" onClick={reportEvent}>
        [report event]
      </div>
      <div role="button" className="modal-option border-bottom" onClick={toggleInviteModal}>
        [send invites]
      </div>
    </div>
  );
};

PostOptionsModal.propTypes = {
  currentUser: PropTypes.shape({
    username: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  postId: PropTypes.number.isRequired,
};

export default PostOptionsModal;
