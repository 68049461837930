import React from 'react';

const Page404 = () => (
  <div className="not-found-container">
    <span className="header">?</span>
    Page not found.
  </div>
);

export default Page404;
