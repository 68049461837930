import { connect } from 'react-redux';
import { openModal, closeModal } from '../../actions/ui_actions';
import { modalTypes as MODAL_TYPES } from '../../constants/modalTypes';
import AuthorOptionsModal from './AuthorOptionsModal';

const mapStateToProps = state => ({
  currentUser: state.session.currentUser,
  postId: state.ui.modals.authorOptionsModal,
  inviteModalOpen: !!state.ui.modals.inviteModal,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal(MODAL_TYPES.AUTHOR_OPTIONS_MODAL)),
  openInviteModal: () => dispatch(openModal(MODAL_TYPES.INVITE_MODAL, true)),
  closeInviteModal: () => dispatch(closeModal(MODAL_TYPES.INVITE_MODAL)),
  deletePost: postId => dispatch({type: 'DELETE_POST', postId: postId})
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthorOptionsModal);
