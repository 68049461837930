import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { getFetchParams } from '@happenings/components/util';
import Store from '@happenings/components/store';

import UpdateButton from '../util/UpdateButton';

import { sendVerificationCode, verifyPhoneNumber, registerNewUser } from '@happenings/components/auth';

type OwnProps = {
  phone: string,
}

const mapState = (state: Store) => ({
  currentUser: state.session.currentUser,
  loading: state.ui.loading.updateInProgress
});

const mapDispatch = {
  sendVerificationCode: (phone: string) => sendVerificationCode(phone),
  // verifyPhoneNumber: (phone: string, code: string) => verifyPhoneNumber(phone, code),
  registerNewUser: (username: string, email: string, password: string) => registerNewUser(username, email, password),
};

const connector = connect(mapState, mapDispatch);
type InjectedProps = ConnectedProps<typeof connector>;

type Props = OwnProps & InjectedProps;

const VerifyPhoneNumber: React.FC<Props> = ({ phone, sendVerificationCode }) => {
  const [code, setCode] = React.useState('');
  const [statusCode, setStatusCode] = React.useState(-1);

  const verify = (code: string) => {
    axios
      .post(`/api/sms/verify-phone-number`, { code}, getFetchParams())
      .then((res) => setStatusCode(res.status))
      .catch((e) => setStatusCode(e.response.status));
  }

  return (
    <div>
      <div className="landing-message">
        <input type="text" placeholder="verification code" onChange={(e) => setCode(e.target.value)} />
        <button onClick={() => verify(code)}>Verify</button>
        {statusCode === 200 && (
          <p>
            Verified! please log in again{' '}
            <Link className="underline" to="/login">
              here
            </Link>
            .
          </p>
        )}
        {statusCode > 0 && statusCode !== 200 && (
          <p>
            Verification failed. Resend verification message{' '}
            {/* TODO saga to call sendVerificationCode */}
          </p>
        )}
        {/* {statusCode === -1 && 'Working...'} */}
      <div>
        Didn't receive a code?
        <UpdateButton text="Resend" handlerFunc={() => sendVerificationCode(phone)} />
      </div>
      </div>
    </div>
  )
}

export default connector(VerifyPhoneNumber);