import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getNotifications, countUnreadNotifications } from '@happenings/components/inbox';

import Header from './Header';

const mapStateToProps = state => ({
  loggedIn: !!state.session.currentUser,
  currentUser: state.session.currentUser,
  unreadNotificationCount: countUnreadNotifications(state),
});

const mapDispatchToProps = dispatch => ({
  getUserNotifications: () => dispatch(getNotifications()),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header));
