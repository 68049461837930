import React from 'react';
import SearchUsersContainer from '../search/SearchUsersContainer';


const InviteModal = () => (
  <div>
    <div className="modal-option border-bottom">
      <SearchUsersContainer />
    </div>
  </div>
);

export default InviteModal;
