import React, { useState } from 'react';
import PropTypes from 'prop-types';

const AddCommentInput = ({ showInputField, addComment }) => {
  const [commentText, setCommentText] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    addComment(commentText);
    setCommentText('');
  };

  const inputField = (
    <div className="input-container">
      <input
        className="comment-input"
        type="text"
        name="commentText"
        value={commentText}
        onChange={e => setCommentText(e.target.value)}
      />
      <div role="button" className="comment-submit-btn" onClick={handleSubmit}>&#8594;</div>
    </div>
  );
  return (
    <div>
      { showInputField ? inputField : null }
    </div>
  );
};

AddCommentInput.propTypes = {
  showInputField: PropTypes.bool.isRequired,
  addComment: PropTypes.func.isRequired,
};

export default AddCommentInput;
