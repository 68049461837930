import React from 'react';
import PropTypes from 'prop-types';

import { Waypoint } from 'react-waypoint';

const ScrollLoader = ({ onEnter, isLoading }) => {
  return (
    <div className="waypoint-container">
      {
        !isLoading
        && <Waypoint scrollableAncestor={window} onEnter={onEnter} />
      }
    </div>
  );
};

ScrollLoader.propTypes = {
  onEnter: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ScrollLoader;
