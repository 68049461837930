export const actionTypes = {
  REGISTER_NEW_USER: 'REGISTER_NEW_USER',
};

export const registerNewUser = (username, email, password) => ({
  type: actionTypes.REGISTER_NEW_USER,
  payload: {
    username, email, password, url: '',
  },
});
