import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const CheckBoxField = ({ field, label, isSelected, handleChange }) => {
  const boxClass = classNames('checkbox', { 'selected': isSelected });
  const privateClass = classNames(boxClass, { 'acct-private': field === 'isPrivate' });
  return (
    <div className="checkbox-container">
      {label}
      <div
        role="button"
        className={privateClass}
        onClick={() => handleChange(field, !isSelected)}
      />
    </div>
  );
};

CheckBoxField.defaultProps = {
  field: null,
};

CheckBoxField.propTypes = {
  field: PropTypes.string,
  label: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default CheckBoxField;
