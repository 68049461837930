import React from 'react';
import PropTypes from 'prop-types';

import NotificationContainer from './NotificationContainer';
import ScrollLoader from '../common/ScrollLoader';
import LoadingIcon from '../common/LoadingIcon';

const NotificationList = ({ notificationIDs = [], notificationsById }) => {
  return notificationIDs.map((notificationId) => {
    const notification = notificationsById[notificationId];
    return (
      <NotificationContainer
        key={notificationId}
        id={notificationId}
        notification={notification}
        postId={notification.post_id}
      />
    );
  });
};

const Inbox = (props) => {
  const { unreadNotificationCount, loaded, getNotifications } = props;
  const notificationWord = unreadNotificationCount === 1
    ? 'notification' : 'notifications';

  return (
    <div>
      <div className="inbox-header">
        <span className="count">{unreadNotificationCount}</span>
        <span>{` unread ${notificationWord}`}</span>
      </div>
      <NotificationList
        notificationIDs={props.notificationIDs}
        notificationsById={props.notificationsById}
      />
      <ScrollLoader onEnter={getNotifications} isLoading={!loaded} />
      { !loaded && <LoadingIcon /> }
    </div>
  );
};

Inbox.propTypes = {
  getNotifications: PropTypes.func.isRequired,
  unreadNotificationCount: PropTypes.number,
  loaded: PropTypes.bool.isRequired,
  notificationIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
  notificationsById: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

Inbox.defaultProps = {
  unreadNotificationCount: 0,
};
export default Inbox;
