// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import moment from 'moment';

import { FetchAction } from '@happenings/components/common/types';
import { ActivityType } from '@happenings/components/feed';
import Store from '@happenings/components/store';
import * as types from '@happenings/components/constants/actionTypes';
import { mapEntitiesById } from '../util';

export type FriendInfo = {
  followee: string;
  interactionType: ActivityType;
};

export type AgeRestriction = 'AGES_ALL' | 'AGES_21' | 'AGES_18';

/**
 * PostBase models the user-definable attributes
 * of a post.
 */
export type PostBase = {
  title: string;
  location: string;
  description: string;
  entityUrl: string;
  isPrivate: boolean;
  isAccessible: boolean;
  guestsCanInvite: boolean;
  ageRestriction: AgeRestriction;
  eventTimestamp: string;
};

/**
 * Post represents all the attributes of an existing,
 * published post as returned by the API.
 */
export type Post = PostBase & {
  id: number;
  username: string; // author
  publicUrl: string;
  thumbPublicUrl: string;
  friendInfo?: FriendInfo;
};

export function formatAgeRestriction(input: AgeRestriction): string {
  const mapping: Record<AgeRestriction, string> = {
    AGES_ALL: 'All ages',
    AGES_21: '21+',
    AGES_18: '18+',
  };
  return mapping[input];
}

export interface PostAction {
  posts: Post[];
  post: Post;
  type: string;
  payload: {
    postId: number;
  };
}

export function formatDate(eventTimestamp: string): string {
  return moment(eventTimestamp).format('LL');
}

/**
 * ACTION CREATORS
 */

export const getPostData = (postId: number) => ({
  type: 'GET_POST_DATA',
  payload: { postId },
});

export const deletePost = (postId: number) => ({
  type: 'DELETE_POST',
  payload: { postId },
});

export const getSaves = (postId: number): FetchAction => ({
  type: 'GET_SAVES',
  payload: { postId },
});

export const toggleSave = (
  postId: number,
  userId: number,
  currentlySaved: boolean
): FetchAction =>
  currentlySaved
    ? { type: 'REMOVE_SAVE', payload: { postId } }
    : { type: 'CREATE_SAVE', payload: { postId, userId } };

/**
 * SELECTORS
 */
export const isUserLiker = (state: Store, postId: number): boolean => {
  if (state.entities.stars[postId] && !!state.session.currentUser) {
    const { username } = state.session.currentUser;
    return state.entities.stars[postId]
      .map((e) => e.username)
      .includes(username);
  }
  return false;
};

export const isUserAttendee = (state: Store, postId: number): boolean => {
  if (state.entities.attendance[postId] && !!state.session.currentUser) {
    const { username } = state.session.currentUser;
    return state.entities.attendance[postId]
      .map((e) => e.username)
      .includes(username);
  }
  return false;
};

export const filterPosts = (
  posts: Record<number, Post>,
  postIDsToGet: number[]
): Record<number, Post> =>
  postIDsToGet.reduce(
    (acc, key) => (posts[key] ? { ...acc, [key]: posts[key] } : acc),
    {}
);

export type State = Record<number, Post>;

export const DEFAULT_STATE: State = {};

// TODO: follow this https://phryneas.de/redux-typescript-no-discriminating-union
const reducer = (state = DEFAULT_STATE, action: PostAction): State => {
  switch (action.type) {
    case types.RECEIVE_FEED_PAGE:
      // TODO normalize state and put authors in user slice of state
      return { ...state, ...(mapEntitiesById(action.posts) as State) };
    case types.RECEIVE_PROFILE_POSTS:
      return { ...state, ...(mapEntitiesById(action.posts) as State) };
    case types.RECEIVE_POST_DATA:
      return { ...state, [action.post.id]: action.post };

    case 'DELETE_POST':
      // eslint-disable-next-line no-case-declarations
      const { [action.payload.postId]: _, ...rest } = state;
      return rest;
    case types.CLEAR_POST_DATA:
      return {};
    default:
      return state;
  }
};

export default reducer;
