import * as types from '../constants/actionTypes';
// eslint-disable-next-line import/no-cycle
import { receiveSessionErrors } from '@happenings/components/errors';

/**
 * action gets picked up by middleware that adds
 * Authorization header + token to params
 */
export const authFetch = (url, params) => ({
  type: types.FETCH_WITH_AUTH,
  url,
  params,
});


export const beginPageFetch = () => ({
  type: types.BEGIN_PAGE_FETCH,
});

export const receiveFeedPage = posts => ({
  type: types.RECEIVE_FEED_PAGE,
  posts,
});

export const beginUpdate = () => ({
  type: types.BEGIN_SERVER_UPDATE,
});

export const updateSuccess = () => ({
  type: types.SERVER_UPDATE_SUCCESS,
});

// check HTTP status code and parse
const resolveResponse = (rawResponse) => {
  if (rawResponse.status >= 200 && rawResponse.status <= 300) {
    return rawResponse.json();
  }
  const error = new Error(rawResponse.statusText);
  error.message = rawResponse.statusText;
  error.statusCode = rawResponse.status;
  throw error;
};

// checks if the resolved response body contains an error message
const handleResolvedApiResponse = (res, successCb) => {
  if (res.error) {
    const error = new Error(res.error);
    error.message = res.error;
    error.statusCode = 400;
    throw error;
  }
  return successCb(res);
};

const handleError = (error, failureCb) => {
  const defaultHandler = () => receiveSessionErrors(error.message, error.statusCode);
  const handler = failureCb || defaultHandler;
  return handler();
};

/**
 *
 * @param {Promise} rawResponse - the promise from an unresolved ajax fetch
 * @param {function} success - callback function on success
 * @param {function} fail - callback on failure
 */
export const handleResponse = (rawResponse, success, fail) => async (dispatch) => {
  try {
    const resolved = await resolveResponse(rawResponse);
    dispatch(handleResolvedApiResponse(resolved, success));
  } catch (error) {
    dispatch(handleError(error, fail));
  }
};
