/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import moment from 'moment';
import { Link } from 'react-router-dom';
import * as NotificationTypes from '../../constants/notificationTypes';

import FollowNotification, { FollowRequestNotification } from './FollowNotification';
import DeleteBtn from './DeleteBtn';


class Notification extends React.Component {
  componentDidMount = () => {
    // only 'event' notifications have postIDs
    if (this.props.postId) {
      this.props.getPostData(this.props.postId);
    }
  }

  formatDate = (eventTimestampString) => {
    const parsedDate = moment(eventTimestampString);
    // console.log(parsedDate);
    const withinOneWeek = moment().add(7, 'days') > parsedDate;
    // e.g. Saturday, September 8th vs 09/08/2010
    return withinOneWeek ? parsedDate.calendar() : parsedDate.format('dddd MMM Do');
  }

  conjugateNotification = (notification, event) => {
    const userLink = (
      <Link to={`/user/${notification.notifier_username}`}>{notification.notifier_username}</Link>
    );
    const authorLink = (
      <Link to={`/user/${event.username}`}>{event.username}</Link>
    );
    switch (notification.notification_type) {
      case (NotificationTypes.INVITE_TO_EVENT):
        return (<span>{userLink} invited you to:</span>);
      case (NotificationTypes.SHARE_EVENT):
        return (
          <span>{userLink} shared {authorLink}&apos;s event</span>
        );
      case (NotificationTypes.EVENT_TIME_CHANGE):
        return (
          <span>Time Change:</span>
        );
      case (NotificationTypes.EVENT_CANCELLED):
        return (
          <span>CANCELLED:</span>
        );
      case (NotificationTypes.USER_ATTENDING):
        return (
          <span>{userLink} will attend your event</span>
        );
      case (NotificationTypes.USER_COMMENTED):
        return (
          <span>{userLink} commented on your event</span>
        );
      case (NotificationTypes.USER_STARRED):
        return (
          <span>{userLink} saved your event</span>
        );
      default:
        return null;
    }
  }

  markAsRead = () => {
    const { notification, markAsRead, currentUser, id } = this.props;
    if (!notification.read) {
      return markAsRead(currentUser.id, id);
    }
    return null;
  }

  deleteHelper = (e) => {
    // don't let the outer markAsRead fire if trying to delete
    if (e) { e.stopPropagation(); }
    const { currentUser, id } = this.props;
    return this.props.deleteNotification(currentUser.id, id);
  }

  render() {
    const {
      currentUser, postId, event, notification, eventLoaded,
    } = this.props;
    if (notification.notification_type === NotificationTypes.FOLLOWED_BY_USER) {
      return (
        <FollowNotification
          followerName={notification.notifier_username}
          read={notification.read}
          deleteNotification={this.deleteHelper}
          markAsRead={() => this.markAsRead(currentUser.id, notification.id)}
        />
      );
    }
    if (notification.notification_type === NotificationTypes.FOLLOW_REQUEST) {
      return (
        <FollowRequestNotification
          userName={currentUser.username}
          followerName={notification.notifier_username}
          read={notification.read}
          deleteNotification={this.deleteHelper}
          markAsRead={() => this.markAsRead(currentUser.id, notification.id)}
        />
      );
    }
    const classname = classNames('notification', { 'unread': !notification.read });
    return eventLoaded ? (
      <div
        className={classname}
        role="button"
        onClick={() => this.markAsRead(currentUser.id, notification.id)}
      >
        <div className="notification-title">
          {this.conjugateNotification(notification, event)}
          <DeleteBtn deleteNotification={this.deleteHelper} />
        </div>
        <div className="notification-body">
          <div>
            <Link to={`/event/${postId}`}>
              <img
                alt={event.title}
                src={event.thumbPublicUrl}
              />
            </Link>
          </div>
          <div className="event-details">
            <h4>{event.title}</h4>
            <div className="date-time-container">
              <span>{this.formatDate(event.eventTimestamp)}</span>
              {/* time just for styling purposes */}
              <span style={{ fontWeight: notification.notification_type
                === NotificationTypes.EVENT_TIME_CHANGE ? '700' : '400' }}
              >
                5pm - 2am
              </span>
            </div>

            <br />
            <br />
          </div>
        </div>
      </div>
    ) : null;
  }
}

Notification.propTypes = {
  id: PropTypes.string.isRequired,
  postId: PropTypes.number,
  currentUser: PropTypes.shape({
    id: PropTypes.number,
    token: PropTypes.string,
    username: PropTypes.string,
  }).isRequired,

  eventLoaded: PropTypes.bool,

  notification: PropTypes.shape({
    id: PropTypes.number,
    notification_type: PropTypes.string,
    notifier_username: PropTypes.string,
    read: PropTypes.bool,
  }).isRequired,

  event: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    location: PropTypes.string,
    eventTimestamp: PropTypes.string,
    thumbPublicUrl: PropTypes.string,
  }),

  getPostData: PropTypes.func.isRequired,
  markAsRead: PropTypes.func.isRequired,
  deleteNotification: PropTypes.func.isRequired,
};

Notification.defaultProps = {
  eventLoaded: false,
  event: null,
  postId: undefined,
};

export default Notification;
