import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CopyEventLink from './CopyEventLink';

// functionalities:
// 1 - edit post (take to new page)
// 2 - delete post
// 3 - copy post URL

const AuthorOptionsModal = ({ postId, closeModal,
  inviteModalOpen,
  openInviteModal,
  closeInviteModal,
  deletePost
}) => {
  const toggleInviteModal = () => {
    if (inviteModalOpen) {
      closeInviteModal();
    } else {
      openInviteModal();
    }
  };

  //confirm deletion
  const safeDeletePost = (postId) => {
    if (confirm("are you sure? event data will be permanently erased.")) {
        deletePost(postId);
    }
  }
  
  return (
    <div>
      <div className="modal-option border-bottom">
        <Link to={`/event/${postId}/edit`} onClick={closeModal}>
          [edit post]
        </Link>
      </div>
      <div role="button" className="modal-option border-bottom" onClick={() => safeDeletePost(postId)}>
          [delete post]
      </div>
      <div role="button" className="modal-option border-bottom" onClick={toggleInviteModal}>
        [send invites]
      </div>
      <div className="modal-option border-bottom">
        <CopyEventLink postId={postId} />
      </div>
    </div>
  );
};

AuthorOptionsModal.propTypes = {
  postId: PropTypes.number.isRequired,
  closeModal: PropTypes.func.isRequired,
  inviteModalOpen: PropTypes.bool.isRequired,
  openInviteModal: PropTypes.func.isRequired,
  closeInviteModal: PropTypes.func.isRequired,
  deletePost: PropTypes.func.isRequired,
};

export default AuthorOptionsModal;
