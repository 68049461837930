/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { API_LOGIN_ERR_MSG } from '@happenings/components/errors';

import UpdateButton from '../util/UpdateButton';
import ApiErrorWrapper from '../util/ErrorWrapper';


const LoginForm = ({ login }) => {
  const { register, handleSubmit, errors, formState } = useForm({ mode: 'onChange' });
  const { isValid } = formState;

  const onSubmit = data => login(data.username, data.password);
  const requiredMsg = <span className="form-error">required</span>;

  return (
    <div className="form">
      <form>
        <input
          type="text"
          name="username"
          placeholder="username"
          ref={register({ required: true })}
        />
        { errors.username && requiredMsg }
        <ApiErrorWrapper trigger={API_LOGIN_ERR_MSG}>
          <input
            type="password"
            name="password"
            placeholder="password"
            ref={register({ required: true })}
          />
        </ApiErrorWrapper>
        { errors.password && requiredMsg }
        <UpdateButton
          text="Log In"
          disabled={!isValid}
          handlerFunc={handleSubmit(onSubmit)}
        />

        <p className="form-help-message">
          Forgot your password?
          {' '}<Link to="/forgot-password" className="underline">Get help here</Link>
        </p>
      </form>
    </div>
  );
};

LoginForm.propTypes = {
  login: PropTypes.func.isRequired,
};

export default LoginForm;
