import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const mapStateToProps = state => ({
  errorMessage: state.errors.error,
});

const ErrorBarModal = ({ errorMessage }) => {
  return (
    <div>
      <div className="modal-err-message">{errorMessage}</div>
    </div>
  );
};

ErrorBarModal.propTypes = {
  errorMessage: PropTypes.string.isRequired,
};

export default connect(
  mapStateToProps,
  null,
)(ErrorBarModal);
