import React from 'react';
import PropTypes from 'prop-types';
// make life easier
import DropZone from 'react-dropzone';

import ErrorWrapper from '../util/ErrorWrapper';
import { IMAGE_FILE_ERROR_MSG } from '../../utils/validation';


class ImageUploader extends React.Component {
  state = {
    files: [],
    fileLoaded: false,
  }

  componentWillUnmount() {
    if (this.state.fileLoaded) {
      this.clearLoadedFiles(this.state.files);
    }
  }

  clearLoadedFiles = (files) => {
    files.forEach((file) => {
      window.URL.revokeObjectURL(file);
    });
  }

  clear = () => {
    if (this.state.fileLoaded) {
      this.clearLoadedFiles(this.state.files);
      this.setState({
        files: [],
        fileLoaded: false,
      });
    }
  }

  onDrop = (files) => {
    this.props.onDrop(files[0]);
    this.setState({
      files,
      fileLoaded: true,
    });
  }

  genPreview(files) {
    return (
      <div>
        <div role="button" onClick={this.clear}>[ x ]</div>
        <div className="preview-container">
          {files.map((file) => {
            return (
              // eslint-disable-next-line jsx-a11y/alt-text
              <img
                key={file.name}
                src={file.preview}
                width="300"
                height="300"
              />
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    const dropZone = (
      <DropZone
        className="dropzone"
        onDrop={this.onDrop}
        multiple={false}
        disableClick={this.state.files.length > 0}
      >
        <div className="upload-icon">
          <div className="box">&#8593;</div>
          <p>Upload poster image</p>
        </div>
      </DropZone>
    );

    return (
      <div className="image-upload-container">
        <ErrorWrapper trigger={IMAGE_FILE_ERROR_MSG}>
          {this.state.fileLoaded ? this.genPreview(this.state.files) : dropZone}
        </ErrorWrapper>
      </div>
    );
  }
}

ImageUploader.propTypes = {
  onDrop: PropTypes.func.isRequired,
};

export default ImageUploader;
