import React from 'react';
import { Link } from 'react-router-dom';

const SupportPage = () => {
  return (
    <div className="public-landing-container">
      <div className="landing-message">
        <p className="header">{'What\'s happening?'}</p>
      </div>
      <p className="form-help-message">
        Need support? Contact us at
        {' '}<a className="underline" href="mailto:hello@whatshappenings.com">hello@whatshappenings.com</a>
      </p>
    </div>
  );
};

export default SupportPage;
