import React from 'react';
import { Link } from 'react-router-dom';

const InboxHeader = () => {
  return (
    <div className="header-container">
      <header className="header-content">
        <Link to="/">{' < Back'}</Link>
        <h1 id="inline-centered" className="logo inline-centered">Inbox</h1>
      </header>
    </div>
  );
};

export default InboxHeader;
