import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

class SearchResultDropdown extends React.Component {
  handleClick(entity) {
    return (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.props.updateSearchedEntity(entity);
    };
  }

  render() {
    const { searchedEntity, userResults, postResults, searchQuery } = this.props;

    const userList = userResults && userResults.map(user => (
      <Link to={`/user/${user.username}`} key={`user-${user.id}`}>
        <li>
          <section className="post-result">
            <strong className="post-title">{user.username}</strong>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p className="post-details">
                {user.bio && user.bio.length > 30
                  ? (
                    <span>
                      {user.bio.substring(0, 30)}
                      ...
                    </span>
                  )
                  : <span>{user.bio}</span>
                }
              </p>
              <div><p className="post-details"><em>User</em></p></div>
            </div>
          </section>
        </li>
      </Link>
    ));

    const postList = postResults.map(post => (
      <Link to={`/event/${post.id}`} key={`post-${post.id}`}>
        <li>
          <section className="post-result">
            <strong className="post-title">{post.title}</strong>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p className="post-details">
                {post.description.length > 30
                  ? (
                    <span>
                      {post.description.substring(0, 30)}
                      ...
                    </span>
                  )
                  : <span>{post.description}</span>
                }
              </p>
              <div><p className="post-details"><em>Post</em></p></div>
            </div>
          </section>
        </li>
      </Link>
    ));

    const usersSelected = classNames({ 'selected': searchedEntity === 'users' });
    const postsSelected = classNames({ 'selected': searchedEntity === 'posts' });
    const results = (searchedEntity === 'users') ? userList : postList;

    const hidden = classNames({
      'hidden': searchQuery.length < 1,
    });

    return (
      <section style={{ zIndex: 1 }} className={`search-dropdown ${hidden}`}>
        <ul className='entity-picker'>
          <li className={usersSelected} onClick={this.handleClick('users')}>Users</li>
          <li className={postsSelected} onClick={this.handleClick('posts')}>Posts</li>
        </ul>
        <ul className="results">
          {results}
        </ul>
      </section>
    );
  }
}

SearchResultDropdown.defaultProps = {
  userResults: [],
  postResults: [],
};

SearchResultDropdown.propTypes = {
  userResults: PropTypes.arrayOf(PropTypes.object),
  postResults: PropTypes.arrayOf(PropTypes.object),
  updateSearchedEntity: PropTypes.func,
  searchQuery: PropTypes.string,
  searchedEntity: PropTypes.string,
};

export default SearchResultDropdown;
