import { Post } from '@happenings/components/post';
import { User } from '@happenings/components/user';

export enum actionTypes {
  SEARCH_USERS = 'SEARCH_USERS',
  SEARCH_POSTS = 'SEARCH_POSTS',
  RECEIVE_SEARCHED_USERS = 'RECEIVE_SEARCHED_USERS',
  RECEIVE_SEARCHED_POSTS = 'RECEIVE_SEARCHED_POSTS',
  UPDATE_SEARCHED_ENTITY = 'UPDATE_SEARCHED_ENTITY',
}

export const SEARCHKEY_THRESHOLD = 2;

type EntityType = 'users' | 'posts';

export interface Search {
  posts: Post[];
  users: User[];
  searchKey: string;
  searchedEntity: EntityType;
  barOpen: boolean;
}

export const DEFAULT_STATE: Search = {
  posts: [],
  users: [],
  searchKey: '',
  searchedEntity: 'users',
  barOpen: false,
};

export type Action = {
  type: string;
  searchKey?: string;
  results?: User[] | Post[];
};

/**
 * Action creators
 */

export const searchUsers = (query: string) =>
  _searchEntity(actionTypes.SEARCH_USERS, query);
export const searchPosts = (query: string) =>
  _searchEntity(actionTypes.SEARCH_POSTS, query);

const _searchEntity = (type: string, query: string) => ({
  type,
  payload: { query },
});

export const clearSearch = () => ({
  type: 'CLEAR_SEARCH',
});

const searchReducer = (state = DEFAULT_STATE, action: Action) => {
  switch (action.type) {
    case actionTypes.RECEIVE_SEARCHED_POSTS:
      return { ...state, posts: action.results };
    case actionTypes.RECEIVE_SEARCHED_USERS:
      return { ...state, users: action.results };
    case actionTypes.UPDATE_SEARCHED_ENTITY:
      return {
        ...state,
        searchedEntity: state.searchedEntity === 'users' ? 'posts' : 'users',
      };
    case 'CLEAR_SEARCH':
      return { ...state, users: [], posts: [], searchKey: '' };
    case 'TOGGLE_BAR':
      return { ...state, barOpen: !state.barOpen };
    case 'CLOSE_SEARCH_BAR':
      return { ...state, barOpen: false };
    default:
      return state;
  }
};

export default searchReducer;
