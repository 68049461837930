import { combineReducers } from 'redux';

import * as types from '@happenings/components/constants/actionTypes';
import notifications from '@happenings/components/inbox';
import userReducer from '@happenings/components/user';
import postReducer from '@happenings/components/post';
import comments from '@happenings/components/entities/comments';
import attendance from '@happenings/components/entities/attendance';

export const selectUserFollowing = (state, username) => {
  if (username in state.entities.follows) {
    return state.entities.follows[username].following;
  }
  return [];
};

export const selectUserFollowers = (state, username) => {
  if (username in state.entities.follows) {
    return state.entities.follows[username].followers;
  }
  return [];
};

export const isFollowing = (state, username) => {
  if (state.session.currentUser) {
    const currUsername = state.session.currentUser.username;
    if (username in state.entities.follows && currUsername !== username) {
      if ('followers' in state.entities.follows[username]) {
        return state.entities.follows[username].followers
          .map(follower => follower.username).includes(currUsername);
      }
    }
  }
  return false;
};

/**
 *  FOLLOWS
 */
const follows = (state = {}, action) => {
  const { username, data } = action;
  switch (action.type) {
    case types.RECEIVE_FOLLOWER_DATA:
      return {
        ...state, [username]: { ...state[username], followers: data },
      };
    case types.RECEIVE_FOLLOWING_DATA:
      return {
        ...state, [username]: { ...state[username], following: data },
      };
    default:
      return state;
  }
};

/**
 *  STARS
 */
const stars = (state = {}, action) => {
  switch (action.type) {
    case types.RECEIVE_POST_STARS:
      return {
        ...state,
        [action.postId]: action.stars,
      };
    default:
      return state;
  }
};

const entitiesReducer = combineReducers({
  users: userReducer,
  posts: postReducer,
  follows,
  stars,
  attendance,
  comments,
  notifications,
});

export default entitiesReducer;
