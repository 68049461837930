
import axios from 'axios';
import { put, takeLatest, call } from 'redux-saga/effects';

import * as types from '@happenings/components/constants/actionTypes';
import { getFetchParams } from '@happenings/components/util';

import { actionTypes as auth } from './index';

type withEmail =  {
  email: string,
};

export function *requestPasswordReset({ payload }: { type: string, payload: any }) {
  try {
    const url = `${payload.url}/api/auth/forgot-password`;
    const params = getFetchParams();
    const { email } = payload;
    yield put({ type: types.BEGIN_SERVER_UPDATE });
    yield call(axios.post, url, { email }, params);
    yield put({ type: types.SERVER_UPDATE_SUCCESS });
  } catch (e) {
    console.error(e);
    const { error } = e.response.data;
    yield put({ type: types.RECEIVE_SESSION_ERRORS, error});
  }
}

interface registerData {
  username: string,
  email: string,
  password: string,
  url: string,
}

export function* sendVerificationCode({ payload }: { type: string, payload: any }) {
  try {
    const url = `${payload.url}/api/sms/send-verification-code`;
    const params = getFetchParams();
    const { phone } = payload;
    yield put({ type: types.BEGIN_SERVER_UPDATE });
    yield call(axios.post, url, { phone }, params);
    yield put({ type: types.SERVER_UPDATE_SUCCESS });
  } catch (e) {
    console.error(e);
    const { error } = e.response.data;
    yield put({ type: types.RECEIVE_SESSION_ERRORS, error});
  }
}

export function* verifyPhoneNumber({ payload}: { type; string, payload: any }) {
  try {
    const url = `${payload.url}/api/sms/verify-phone-number`;
    const params = getFetchParams();
    const { phone, code } = payload;
    yield put({ type: types.BEGIN_SERVER_UPDATE });
    yield call(axios.post, url, { phone, code }, params);
    yield put({ type: types.SERVER_UPDATE_SUCCESS });
  } catch (e) {
    console.error(e);
    const { error } = e.response.data;
    yield put({ type: types.RECEIVE_SESSION_ERRORS, error });
  }

}

export function* registerNewUser({ payload }: { type: string; payload: registerData; }) {
  try {
    const url = `${payload.url}/api/auth/register`;
    const params = getFetchParams();
    const { username, email, password } = payload;
    yield put({ type: types.BEGIN_SERVER_UPDATE });
    const res = yield call(
      axios.post, url, { username, email, password }, params,
    );
    const currentUser = res.data;
    yield put({ type: types.RECEIVE_CURRENT_USER, currentUser });
    yield put({ type: types.SERVER_UPDATE_SUCCESS });
  } catch (e) {
    if (e.response && e.response.status === 400) {
      const { error } = e.response.data;
      yield put({ type: types.RECEIVE_SESSION_ERRORS, error });
    } else {
      yield put({ type: types.RECEIVE_SESSION_ERRORS, statusCode: 500 });
    }
  }
}

export default function* authSaga() {
  yield takeLatest(auth.REGISTER_NEW_USER, registerNewUser);
  yield takeLatest('REQUEST_PASSWORD_RESET', requestPasswordReset);
  yield takeLatest(auth.SEND_VERIFICATION_CODE, sendVerificationCode);
  yield takeLatest(auth.VERIFY_PHONE_NUMBER, verifyPhoneNumber);
}
