import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getPostData } from '@happenings/components/post';
import {
  createEvent,
  updateEvent,
  clearFormFields,
} from '@happenings/components/create';

import EditPostPage from './EditPostPage';

const mapStateToProps = (state, ownProps) => ({
  loggedIn: !!state.session.currentUser,
  currUser: state.session.currentUser,
  posts: state.entities.posts,
  postId: ownProps.match.params.event_id,
  imgLoaded: !!state.ui.form.localImage,
  // bypass if we don't need to load existing post data
  loaded:
    ((!state.ui.loading.postsLoading &&
      state.entities.posts[ownProps.match.params.event_id]) ||
      ownProps.isNewEvent) &&
    !state.ui.loading.eventUploading,
  eventImageCleared: state.ui.form.eventImageCleared,
  isNewEvent: ownProps.isNewEvent || false,

  newlyCreatedPostId: state.ui.form.newlyCreatedPostId,
});

const mapDispatchToProps = (dispatch) => ({
  clearFormFields: () => dispatch(clearFormFields()),
  initForm: (postId) => dispatch(getPostData(postId)),

  // passing history object so redux action can redirect to new event page
  createEvent: (formData) => dispatch(createEvent(formData)),
  updateEvent: (postID, formData) => dispatch(updateEvent(postID, formData)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditPostPage)
);
