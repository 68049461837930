import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getFollowers, getFollowing } from '@happenings/components/entities/follows';
import { fetchUserByUsername } from '@happenings/components/user';
import { clearSessionErrors } from '@happenings/components/errors';

import ProfilePage from './ProfilePage';


const mapStateToProps = (state, ownProps) => ({
  currentUser: state.session.currentUser,
  username: ownProps.match.params.username,
  loaded: (
    !!state.entities.users[ownProps.match.params.username]
    && state.ui.loading.userLoading === false
  ),
  notFound: state.errors.statusCode === 404,
});

const mapDispatchToProps = dispatch => ({
  initUserPage: (username) => {
    dispatch(clearSessionErrors());
    dispatch(fetchUserByUsername(username));
    dispatch(getFollowers(username));
    dispatch(getFollowing(username));
  },
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfilePage));
