import { connect } from 'react-redux';

import SearchUsersResultDropdown from './SearchUsersDropDown';
import { selectInviteUserResults } from '../../reducers/selectors';
import { closeModal, clearInviteUsersSearch } from '../../actions/ui_actions';
import { inviteUsersToPost } from '../../actions/post_actions';
import { modalTypes as MODAL_TYPES } from '../../constants/modalTypes';

const mapStateToProps = state => ({
  userResults: selectInviteUserResults(state)
});

const mapDispatchToProps = dispatch => ({
  inviteUsers: (postId, userIds) => dispatch(inviteUsersToPost(postId, userIds)),
  closeModal: () => dispatch(closeModal(MODAL_TYPES.INVITE_MODAL)),
  clearSearch: () => dispatch(clearInviteUsersSearch()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchUsersResultDropdown);
