import React from 'react';
import PropTypes from 'prop-types';

const DeleteNotificationBtn = ({ deleteNotification }) => {
  return (
    <button className="attend-btn cursor-pointer" type="button" onClick={deleteNotification}>
      x
    </button>
  );
};

DeleteNotificationBtn.propTypes = {
  deleteNotification: PropTypes.func.isRequired,
};

export default DeleteNotificationBtn;
