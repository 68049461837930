const MONTH = 'MONTH';
const DAY = 'DAY';
const YEAR = 'YEAR';

export const TIMELINE_GRANULARITIES = {
  DAY,
  MONTH,
  YEAR,
};

export const TIMELINE_GROUPBY_KEYS = {
  [DAY]: 'YYYY-MM-DD',
  [MONTH]: 'YYYY-MM',
  [YEAR]: 'YYYY',
};

export const TIMELINE_DISPLAY_STRINGS = {
  [DAY]: 'LL', // December 9, 2019
  [MONTH]: 'MMMM YYYY', // December 2019
  [YEAR]: 'YYYY', // 2019
};

const ATTENDING = 'ATTENDING';
const STARRED = 'STARRED';
const HOST = 'HOST';

export const TIMELINE_FEED_TYPES = {
  HOST,
  STARRED,
  ATTENDING,
};

export const GRANULARITY_DISPLAY_NAMES = {
  [DAY]: 'Week', // lol
  [MONTH]: 'Month',
  [YEAR]: 'Year',
};

export const TIMELINE_DISPLAY_NAMES = {
  [ATTENDING]: 'Attending',
  [STARRED]: 'Saved',
  [HOST]: 'Hosting',
};

export const GRANULARITY_OPTIONS = [
  DAY, MONTH, YEAR,
];

export const TIMELINE_OPTIONS = [
  HOST, STARRED, ATTENDING,
];
