import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getFollowers, getFollowing, updateFollow } from '@happenings/components/entities/follows';
import UpdateButton from '../util/UpdateButton';
import KeySelector from '../util/KeySelector';
import LoadingIcon from '../common/LoadingIcon';

import { currentUserFollowing } from '../../reducers/selectors';

// TODO: this is so sad find a more elegant way to check this.
const isLoaded = (state, username) => {
  if (state.session.currentUser) {
    const currUsername = state.session.currentUser.username;
    if (username in state.entities.follows) {
      if ('followers' in state.entities.follows[username]) {
        if (currUsername in state.entities.follows) {
          if ('following' in state.entities.follows[currUsername]) {
            return true;
          }
        }
      }
    }
  }
  return false;
};

const mapStateToProps = (state, ownProps) => ({
  profileUsername: ownProps.match.params.username,
  currentUser: state.session.currentUser,
  currUserFollowing: currentUserFollowing(state),
  followData: state.entities.follows,
  loaded: isLoaded(state, ownProps.match.params.username),
});

const mapDispatchToProps = dispatch => ({
  getFollows: (username) => {
    dispatch(getFollowers(username));
    dispatch(getFollowing(username));
  },
  updateFollow: (currUserId, followeeUsername, val) => (
    dispatch(updateFollow(currUserId, followeeUsername, val))
  ),
});

/**
 * FollowRow is a helper component for FollowPage
 */
export const FollowRow = ({
  userName, currUserIsFollowing, isCurrUser, awaitingApproval, followHandler,
}) => {
  let btnText = currUserIsFollowing ? 'Following' : 'Follow';
  btnText = awaitingApproval ? 'Pending' : btnText;

  const btnClass = classNames('follow-btn', { 'following': currUserIsFollowing });
  return (
    <div className="follow-row">
      <Link to={`/user/${userName}`}>{userName}</Link>
      { !isCurrUser
        ? (
          <UpdateButton
            className={btnClass}
            text={btnText}
            handlerFunc={followHandler}
            disable={currUserIsFollowing}
          />
        ) : null }
    </div>
  );
};

FollowRow.propTypes = {
  userName: PropTypes.string.isRequired,
  currUserIsFollowing: PropTypes.bool.isRequired,
  isCurrUser: PropTypes.bool.isRequired,
  awaitingApproval: PropTypes.bool.isRequired,
  followHandler: PropTypes.func.isRequired,
};

class FollowPage extends React.Component {
  state = {
    selection: 'followers',
  }

  componentDidMount() {
    if (!this.props.followData[this.props.profileUsername]) {
      this.props.getFollows(this.props.profileUsername);
    }
    if (!this.props.followData[this.props.currentUser.username]) {
      this.props.getFollows(this.props.currentUser.username);
    }
  }

  followHandler = (followUsername, value) => {
    this.props.updateFollow(followUsername, value);
  }

  renderFollowData = (follows) => {
    return follows.map((follow) => {
      const currUserFollowing = this.props.currUserFollowing
        .includes(follow.username);

      return (
        <FollowRow
          key={follow.username}
          userName={follow.username}
          currUserIsFollowing={currUserFollowing && !follow.awaiting_approval}
          awaitingApproval={follow.awaiting_approval}
          isCurrUser={this.props.currentUser.username === follow.username}
          followHandler={() => this.followHandler(follow.username, !currUserFollowing)}
        />
      );
    });
  }

  render() {
    if (this.props.loaded) {
      // payload = { followers, following }
      const payload = this.props.followData[this.props.profileUsername];
      return (
        <div className="follow-page-container">
          <KeySelector
            keyList={['followers', 'following']}
            containerClass="follow-list-selectors"
            btnClass="unselected-btn"
            selectedClass="selected"
            btnNameMap={{ 'followers': 'Followers', 'following': 'Following' }}
            selection={this.state.selection}
            onChange={selection => this.setState({ selection })}
          />
          <div className="follow-container">
            {this.renderFollowData(payload[this.state.selection])}
          </div>
        </div>
      );
    }
    return <LoadingIcon />;
  }
}

FollowPage.propTypes = {
  profileUsername: PropTypes.string.isRequired,
  currentUser: PropTypes.shape({
    username: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  currUserFollowing: PropTypes.arrayOf(PropTypes.string).isRequired,
  followData: PropTypes.shape({
    followers: PropTypes.arrayOf(PropTypes.shape({
      username: PropTypes.string,
      follower_id: PropTypes.number,
    })),
    following: PropTypes.arrayOf(PropTypes.shape({
      username: PropTypes.string,
      user_id: PropTypes.number,
    })),
  }).isRequired,
  loaded: PropTypes.bool.isRequired,
  getFollows: PropTypes.func.isRequired,
  updateFollow: PropTypes.func.isRequired,
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(FollowPage));
