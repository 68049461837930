import React from 'react';
import PropTypes from 'prop-types';

import PostContainer from './PostContainer';
import LoadingIcon from '../common/LoadingIcon';

/**
 * Publically viewable Event page, don't need to be signed in. Will redirect to
 * public landing if 'like' or 'attend' is clicked with no signed in user.
 */

class PostPage extends React.Component {
  componentDidMount() {
    const id = this.props.match.params.event_id;

    // only fetch if no posts currently loading and post isnt already loaded
    // if (!this.props.post && this.props.loaded) {
    this.props.getPostData(id);
    // }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      window.location.reload();
    }
  }

  render() {
    const id = parseInt(this.props.match.params.event_id);
    const { post, postNotFound, history } = this.props;

    const hasPostData = this.props.loaded && !!post;
    const fallback = postNotFound ? null : <LoadingIcon />;

    return (
      <div>
        {hasPostData ? (
          <PostContainer
            id={id} // :'(
            post={post}
            history={history}
          />
        ) : fallback}
      </div>
    );
  }
}

PostPage.propTypes = {
  loaded: PropTypes.bool.isRequired,
  post: PropTypes.shape({
    username: PropTypes.string,
    publicUrl: PropTypes.string,
    thumbPublicUrl: PropTypes.string,
    eventTimestamp: PropTypes.string,
    title: PropTypes.string,
    location: PropTypes.string,
    description: PropTypes.string,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      event_id: PropTypes.string,
    }),
  }).isRequired,

  // URL location, not event location
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,

  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  getPostData: PropTypes.func.isRequired,
  postNotFound: PropTypes.bool.isRequired,
};

PostPage.defaultProps = {
  // we allow that the post may not be loaded yet.
  post: undefined,
};

export default PostPage;
