import React from 'react';
import PropTypes from 'prop-types';

import { MIN_PASSWORD_LENGTH } from '@happenings/components/constants';

import AccountSettingsForm from './AccountSettingsForm';
import LoadingIcon from '../common/LoadingIcon';

class AccountSettings extends React.Component {
  componentDidMount() {
    this.props.fetchUserByUsername(this.props.currentUser.username);
  }

  validatePassword = (newPw, confirmPw) => {
    if (newPw !== confirmPw) {
      return { isValid: false, error: 'password fields did not match' };
    }
    if (newPw !== '' && newPw < MIN_PASSWORD_LENGTH) {
      return {
        isValid: false,
        error: 'password must be at least 6 characters',
      };
    }
    return { isValid: true, error: null };
  };

  handleSubmit = (formState) => {
    this.props.updateUserInfo(this.props.userInfo.id, formState);
  };

  toggleDeleteAccountModal = () => {
    if (this.props.deleteAccountModalOpen) {
      this.props.closeDeleteAccountModal();
    } else {
      this.props.openDeleteAccountModal();
    }
  };

  logOutHelper = (history, callback) => {
    history.push('/');
    callback();
  };

  render() {
    const { email, username, bio, isPrivate, userType } = this.props.userInfo;
    const logOutHandler = () => this.logOutHelper(this.props.history, this.props.logOut);
    return this.props.loaded ? (
      <div className="account-settings-container">
        <AccountSettingsForm
          username={username}
          email={email}
          bio={bio}
          userType={userType || 'PERSON'}
          isPrivate={isPrivate}
          onSubmit={this.handleSubmit}
        />
        <div className="submit-btn-white" role="button" onClick={logOutHandler}>
          Sign Out
        </div>
        <div className="submit-btn-red" role="button" onClick={this.toggleDeleteAccountModal}>
          Delete Account
        </div>
      </div>
    ) : <LoadingIcon />;
  }
}

AccountSettings.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number,
    token: PropTypes.string,
    username: PropTypes.string,
  }).isRequired,
  userInfo: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    bio: PropTypes.string,
    isPrivate: PropTypes.bool,
    userType: PropTypes.string,
    username: PropTypes.string,
  }),
  loaded: PropTypes.bool.isRequired,
  fetchUserByUsername: PropTypes.func.isRequired,
  updateUserInfo: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  logOut: PropTypes.func.isRequired,

  deleteAccountModalOpen: PropTypes.bool.isRequired,
  openDeleteAccountModal: PropTypes.func.isRequired,
  closeDeleteAccountModal: PropTypes.func.isRequired,
};

AccountSettings.defaultProps = {
  userInfo: {},
};

export default AccountSettings;
