import * as types from '@happenings/components/constants/actionTypes';
import { Action as NotificationAction } from '@happenings/components/inbox';
import { FeedAction } from '@happenings/components/feed';
import { PostAction, TimelineType } from '@happenings/components/profile';

type PageStrategy = (candidates: number[]) => number | null;

// TODO: move all of this logic out of the client into the API.
// have everything use the 'LAST_FIRST' strategy and then remove
// the PagingStrategies concept entirely.
export const PagingStrategies: Record<string, PageStrategy> = {
  // when displaying the lowest (oldest) incrementing ID first,
  // the highest ID among the candidates is the last evaluated key.
  OLDEST_FIRST: (candidates) => Math.max(...candidates),
  // when displaying the highest (newest) incrementing ID first,
  // the loweset ID among the candidates is the last evaluated key.
  NEWEST_FIRST: (candidates) => Math.min(...candidates),
  // when the ordering is defined by the API, the last candidate in
  // the page of results is the last evaluated key.
  LAST_FIRST: (candidates) => candidates.pop() || null,
};

export const selectLastEvaluatedKey = (
  prevKey: number | null,
  candidates: number[],
  strategy = PagingStrategies.NEWEST_FIRST
): number | null => (candidates.length === 0 ? prevKey : strategy(candidates));

export interface PageState {
  lastEvaluatedKey: number | null;
  hasMore: boolean;
  keys: number[];
}

export interface ProfileState {
  ATTENDING: PageState;
  HOST: PageState;
  STARRED: PageState;
}

export const DEFAULT_STATE: PageState = {
  lastEvaluatedKey: null,
  hasMore: true,
  keys: [],
};

export type Pagination = {
  inbox: PageState;
  feed: PageState;
  // keyed by username
  profile: Record<string, ProfileState>;
};

const PROFILE_DEFAULT_STATE = {
  ATTENDING: DEFAULT_STATE,
  HOST: DEFAULT_STATE,
  STARRED: DEFAULT_STATE,
};

const INITIAL_STATE: Pagination = {
  inbox: DEFAULT_STATE,
  feed: DEFAULT_STATE,
  profile: {},
};

export type PaginationActionType = NotificationAction & FeedAction & PostAction;

/**
 * @param prevKey the last evaluated key
 * @param prevKeys keys already in the store
 * @param pageKeys new list of keys from the server
 */
export const paginator = (
  prevKey: number | null,
  prevKeys: number[],
  pageKeys: number[],
  strategy = PagingStrategies.NEWEST_FIRST
): PageState => ({
  lastEvaluatedKey: selectLastEvaluatedKey(prevKey, pageKeys, strategy),
  hasMore: pageKeys.length > 0,
  // append to the end of the list
  keys: [...prevKeys, ...pageKeys],
});


const withDefaultFallback = (
  ps: Record<string, ProfileState>,
  username: string
): ProfileState => {
  if (!ps[username]) {
    return PROFILE_DEFAULT_STATE;
  }
  return ps[username];
};

const reduceFeedTypeForUsername = (
  ps: Record<string, ProfileState>,
  username: string,
  feedType: string,
  postIDs: number[]
) => {
  const newState = withDefaultFallback(ps, username);
  const newTimeline = newState[feedType] ?? DEFAULT_STATE;

  return paginator(
    newTimeline.lastEvaluatedKey,
    newTimeline.keys,
    postIDs,
    PagingStrategies.LAST_FIRST
  );
};

const paginationReducer = (
  state = INITIAL_STATE,
  action: PaginationActionType
): Pagination => {
  switch (action.type) {
    case types.RECEIVE_FEED_PAGE:
      return {
        ...state,
        feed: {
          ...state.feed,
          ...paginator(
            state.feed.lastEvaluatedKey,
            state.feed.keys,
            action.posts.map((p) => p.seqNum)
          ),
        },
      };
    case types.RECEIVE_NOTIFICATION_PAGE:
      return {
        ...state,
        inbox: {
          ...state.inbox,
          ...paginator(
            state.inbox.lastEvaluatedKey,
            state.inbox.keys,
            action.notifications.map((n) => n.id)
          ),
        },
      };
    case types.RECEIVE_PROFILE_POSTS:
      return {
        ...state,
        profile: {
          ...state.profile,
          [action.username]: {
            ...withDefaultFallback(state.profile, action.username),
            [action.feedType]: {
              ...reduceFeedTypeForUsername(
                state.profile,
                action.username,
                action.feedType,
                action.posts.map((p) => p.id)
              ),
            },
          },
        },
      };
    // to-do: don't clear once we can initialize profilePostIds in profile
    // with the paginated postIds for the new timeline
    case types.TIMELINE_FEED_CHANGE:
      return {
        ...state,
        profile: {},
      };
    case types.ROUTE_CHANGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default paginationReducer;
