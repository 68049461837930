import React from 'react';
import LegalPageHeader from './LegalPageHeader';

import html from './e-u-l-a';

const Privacy = () => {
  return (
    <div className="legal-page eula">
      <LegalPageHeader title={'EULA'} />
      <div className="text-content" dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  );
};

export default Privacy;
