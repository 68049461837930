import * as types from '@happenings/components/constants/actionTypes';
import { Post } from '@happenings/components/post';
import Store from '@happenings/components/store';

// The following action types are used in both the native and web apps
export enum actionTypes {
  GET_PROFILE_POSTS = 'GET_PROFILE_POSTS',
  RECEIVE_PROFILE_POSTS = 'RECEIVE_PROFILE_POSTS',
}

export type TimelineType = 'HOST' | 'STARRED' | 'ATTENDING';
export type GranularityType = 'DAY' | 'MONTH' | 'YEAR';

export type PostAction = {
  type: string;
  posts: Post[];
  feedType: TimelineType;
  username: string;
};

export const getProfilePosts = (username: string, feedType: string) => ({
  type: actionTypes.GET_PROFILE_POSTS,
  payload: {
    username,
    feedType,
  },
});

type FeedPostIds = Record<TimelineType, number[]>;

const INITIAL_USERNAME_STATE: FeedPostIds = {
  HOST: [],
  STARRED: [],
  ATTENDING: [],
};

// interface for slice of state
export interface Profile {
  postIDsByUsername: Record<string, FeedPostIds>;
}

export const INITIAL_STATE: Profile = {
  postIDsByUsername: {},
};

// selector
export const postIDsForTimeline = (
  state: Store,
  username: string,
  timeline: TimelineType
) => {
  if (state.profile.postIDsByUsername[username]) {
    return state.profile.postIDsByUsername[username][timeline] || [];
  }
  return [];
};


const reduceForUsername = (
  byUsername: Record<string, FeedPostIds>,
  username: string,
  feedType: TimelineType,
  postIDs: number[]
) => {
  const stateForUsername = byUsername[username] ?? INITIAL_USERNAME_STATE;
  const stateForFeedType = stateForUsername[feedType] ?? [];
  return {
    ...byUsername,
    [username]: {
      ...stateForUsername,
      [feedType]: [...stateForFeedType, ...postIDs],
    },
  };
};

const profileReducer = (
  state: Profile = INITIAL_STATE,
  action: PostAction
): Profile => {
  switch (action.type) {
    case actionTypes.RECEIVE_PROFILE_POSTS: {
      return {
        ...state,
        postIDsByUsername: reduceForUsername(
          state.postIDsByUsername,
          action.username,
          action.feedType,
          action.posts.map((p) => p.id)
        ),
      };
    }
    // to-do: initialize with post IDs in the timeline type from pagination
    case types.TIMELINE_FEED_CHANGE:
      return INITIAL_STATE;
    // legacy from the web
    case types.ROUTE_CHANGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default profileReducer;
