import { combineReducers } from 'redux';

import loading from '@happenings/components/reducers/loading';
import search from '@happenings/components/search';
import form from '@happenings/components/create';

import modals from './modals';

const uiReducer = combineReducers({
  loading,
  search,
  modals,
  form,
});

export default uiReducer;
