import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { openModal } from '../../actions/ui_actions';
import { modalTypes as MODAL_TYPES } from '../../constants/modalTypes';
import flipIcon from '../../assets/flip-icon.svg';


const mapStateToProps = (state, ownProps) => ({
  loggedIn: !!state.session.currentUser,
  postOptionsModalOpen: !!state.ui.modals.postOptionsModal,
  currUserIsAuthor: (
    !!state.session.currentUser
    && state.session.currentUser.username === ownProps.username
  ),
});

const mapDispatchToProps = dispatch => ({
  openPostOptionsModal: postId => dispatch(openModal(MODAL_TYPES.POST_OPTIONS_MODAL, postId)),
  openAuthorOptionsModal: postId => dispatch(openModal(MODAL_TYPES.AUTHOR_OPTIONS_MODAL, postId)),
});

const PostHeader = (props) => {
  return (
    <div className="post-header">
      <div className="post-author">
        <Link to={`/user/${props.username}`}>
          {props.username}
        </Link>
      </div>
      <div role="button" onClick={() => { props.toggleDetailView() }}>
        <img className="flip-icon" src={flipIcon} alt="flip to post detail view" />
      </div>
      {props.loggedIn}
    </div>
  );
};

PostHeader.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  username: PropTypes.string.isRequired,
  toggleDetailView: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PostHeader);
