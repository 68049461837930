import { fetch } from 'whatwg-fetch';
import { createStore, applyMiddleware, compose } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';


import createConfigMiddleware from '@happenings/components/middleware';
import fetchMiddleware from './middleware/index';
import rootReducer from './reducers/root';
import rootSaga from './sagas';

// url is empty cause of same-origin on web :)
const webConfig = { url: '' };

const configureStore = (preloadedState = {}) => {
  /** middleware to grab jwt token from the store
   * and add it as an Authorization header so we
   * can keep our actions clean. This wraps the
   * whatwg-fetch implementation.
   */
  const encapsulatedFetch = fetchMiddleware(fetch);
  const configHandler = createConfigMiddleware(webConfig);
  const sagaMiddleWare = createSagaMiddleware();

  const middleware = [
    thunk,
    encapsulatedFetch,
    configHandler,
    sagaMiddleWare,
  ];

  if (process.env.NODE_ENV !== 'production') {
    middleware.push(logger);
  }

  // eslint-disable-next-line operator-linebreak
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;

  const middleWares = composeEnhancers(
    applyMiddleware(...middleware),
  );

  const store = createStore(
    rootReducer,
    preloadedState,
    middleWares,
  );

  sagaMiddleWare.run(rootSaga);

  return store;
};

export default configureStore;
