import axios from 'axios';
import { put, takeEvery, call, select } from 'redux-saga/effects';

import * as types from '@happenings/components/constants/actionTypes'
import { getCurrUser, getToken } from '@happenings/components/session';
import { PageState } from '@happenings/components/pagination';
import { getFetchParams } from '@happenings/components/util';
import { ApiAction, ApiPayload } from '../common/types';
import Store from '../store';


interface inboxAction extends ApiAction {
  payload: ApiPayload & { id?: number };
}

export const getPagination = (state: Store): PageState => state.pagination.inbox;

export function* getUserNotifications({ payload }: inboxAction) {
  try {
    const { lastEvaluatedKey, hasMore } = yield select(getPagination);
    if (hasMore) {
      const token = yield select(getToken);
      const user = yield select(getCurrUser);
      const pageParam = lastEvaluatedKey ? `?startAfter=${lastEvaluatedKey}` : '';
      const url = `${payload.url}/api/inbox/${user.id}${pageParam}`;
      const params = getFetchParams(token);
      yield put({ type: types.BEGIN_NOTIFICATION_FETCH });
      const res = yield call(axios.get, url, params);
      yield put({ type: types.RECEIVE_NOTIFICATION_PAGE, notifications: res.data });
    }
  } catch (e) {
    yield put({ type: types.RECEIVE_SESSION_ERRORS, statusCode: 500 });
  }
}

export function* markNotificationAsRead({ payload }: inboxAction) {
  try {
    const token = yield select(getToken);
    const user = yield select(getCurrUser);
    const url = `${payload.url}/api/inbox/${user.id}`;
    yield call(axios.put, url, { notificationId: payload.id }, getFetchParams(token));
    yield put({ type: types.MARK_NOTIFICATION_AS_READ, id: payload.id });
  } catch (e) {
    yield put({ type: types.RECEIVE_SESSION_ERRORS, statusCode: e.response.status });
  }
}

export function* deleteNotification({ payload }: inboxAction) {
  try {
    const user = yield select(getCurrUser);
    const url = `${payload.url}/api/inbox/${user.id}?notificationId=${payload.id}`;
    const token = yield select(getToken);
    const params = getFetchParams(token);
    yield call(axios.delete, url, params);

    // re-fetch updated server-side data
    yield put({ type: 'GET_NOTIFICATIONS', payload });
  } catch (e) {
    yield put({ type: types.RECEIVE_SESSION_ERRORS, statusCode: 500 });
  }
}

export default function* main() {
  yield takeEvery('GET_NOTIFICATIONS', getUserNotifications);
  yield takeEvery('DELETE_NOTIFICATION', deleteNotification);
  yield takeEvery('READ_NOTIFICATION', markNotificationAsRead);
}
