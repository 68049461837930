import React from 'react';
import PropTypes from 'prop-types';
import Comment from './Comment';

const PostCaption = ({ username, text }) => {
  return (
    <div className="post-caption">
      <Comment username={username} text={text} />
    </div>
  );
};

PostCaption.propTypes = {
  username: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};


export default PostCaption;
