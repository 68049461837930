/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';

import configureStore from './store';

import RootErrorBoundary from './components/errors/RootErrorBoundary';
import AppContainer from './components/AppContainer';
import './styles/index.scss';

// configure client side error reporting.
if (process.env.NODE_ENV === 'production') {
  Sentry.init({ dsn: 'https://77fe7d70125448bfb35e43d196ad6c0e@o415401.ingest.sentry.io/5306421' });
}


const root = ReactDOM.createRoot(document.getElementById('app'));
root.render((
  <Provider store={configureStore()}>
    <BrowserRouter>
      <RootErrorBoundary>
        <AppContainer />
      </RootErrorBoundary>
    </BrowserRouter>
  </Provider>
));
