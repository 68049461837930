const MAX_IMAGE_BYTE_SIZE = 5000000; // 5mb

const VALID_IMAGE_FORMATS = ['image/jpeg', 'image/png'];

export const IMAGE_FILE_ERROR_MSG =
  'image must be JPEG or PNG and less than 5 megabytes';

/**
 * validates a local image file on size and file type
 * @param {File} localImage
 */
function validateImageFile(localImage: File): boolean {
  const imageSizeValid = localImage.size <= MAX_IMAGE_BYTE_SIZE;
  const fileTypeValid = VALID_IMAGE_FORMATS.includes(localImage.type);
  return imageSizeValid && fileTypeValid;
}

// eslint-disable-next-line import/prefer-default-export
export function validateFormData(localImage: File | undefined) {
  if (!!localImage) {
    const imageIsValid = validateImageFile(localImage);
    if (!imageIsValid) {
      return IMAGE_FILE_ERROR_MSG;
    }
  }
  return null;
}
