import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Comment = ({ id, username, text, userIsAuthor, deleteCommentCallback }) => {
  return (
    <div className="comment">
      <Link to={`/user/${username}`}>
        <span className="comment-author">{username}</span>
      </Link>
      {' '}
      <span>{text}</span>
      {userIsAuthor && deleteCommentCallback !== undefined && id !== undefined ? (
        <button type="button" onClick={() => { deleteCommentCallback(id); }} className="delete-indicator">
          x
        </button>
      ) : null
      }
    </div>
  );
};

Comment.propTypes = {
  id: PropTypes.number,
  username: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  userIsAuthor: PropTypes.bool,
  deleteCommentCallback: PropTypes.func,
};

Comment.defaultProps = {
  deleteCommentCallback: undefined, // defined only if coming from the comments page
  userIsAuthor: false,
  id: undefined, // undefined if comment is the caption
};


export default Comment;
