import { Middleware } from 'redux';

export interface PlatformConfig {
  url: string;
}

const configMiddleware = (config: PlatformConfig): Middleware<{}> => () => next => action => {
  if ('payload' in action) {
    const enrichedAction = {
      ...action,
      payload: { ...action.payload, ...config },
    }
    return next(enrichedAction);
  }
  return next(action);
};

export default configMiddleware;
