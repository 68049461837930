import React from 'react';
import PropTypes from 'prop-types';

import KeySelector from '../util/KeySelector';
import { AGES_ALL, AGES_18, AGES_21 } from '../../constants/ageRestrictions';

const AgeRestrictionField = ({ ageRestriction, handleChange }) => {
  const displayNames = {
    [AGES_ALL]: 'all',
    [AGES_18]: '18+',
    [AGES_21]: '21+',
  };
  const FIELD_NAME = 'ageRestriction';
  return (
    <KeySelector
      keyList={[AGES_ALL, AGES_18, AGES_21]}
      containerClass="checkbox-container"
      btnNameMap={displayNames}
      btnClass="checkbox age-btn"
      selection={ageRestriction}
      onChange={key => handleChange(FIELD_NAME, key)}
    />
  );
};

AgeRestrictionField.propTypes = {
  ageRestriction: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default AgeRestrictionField;
