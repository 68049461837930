/**
 * ResetPasswordPage is the page a user is taken to when they click an
 * email verification link in their inbox.
 */
import * as React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { MIN_PASSWORD_LENGTH } from '@happenings/components/constants';
import UpdateButton from '../util/UpdateButton';
import { getFetchParams } from '@happenings/components/util';
import { useQuery } from '../util';

const ResetPassword: React.FC<{}> = () => {
  const { register, handleSubmit, watch, errors, formState } = useForm({ mode: 'onChange' });
  const { isValid } = formState;

  let query = useQuery();
  const resetToken = query.get('token');

  type ResetRequestState = 'INIT' | 'LINK_VALID' | 'LINK_INVALID' | 'RESET_SUCCESS';
  const [ resetRequestState, setResetRequestState ] = React.useState<ResetRequestState>('INIT');

  const onSubmit = (formData) => {
    console.log(formData);
    axios.post('/api/auth/reset-password',
      { token: resetToken, password: formData.password },
      getFetchParams(),
    ).then(() => setResetRequestState('RESET_SUCCESS'));
    // TODO error handling, 500?
  };

  React.useEffect(() => {
    axios.get(`/api/auth/validate-reset-request?token=${resetToken}`)
      .then(res => setResetRequestState(res.status === 200 ? 'LINK_VALID' : 'LINK_INVALID'))
      .catch(e => setResetRequestState('LINK_INVALID'));
  }, []);

  const fmtFormError = msg => <span className="form-error">{msg}</span>;

  const resetForm = (
    <div className="signup-form">
      <div>
        Reset password
      </div>
      <form className="form">
        <input
          type="password"
          name="password"
          placeholder="new password"
          ref={register({
            required: 'password required',
            minLength: { value: MIN_PASSWORD_LENGTH, message: 'need longer password' },
          })}
        />
        {errors.password && fmtFormError(errors.password.message)}
        <input
          type="password"
          name="passwordConfirm"
          placeholder="confirm password"
          ref={
            register({
              required: 'confirm password',
              validate: val => val === watch('password') || 'passwords do not match',
            })}
        />
        {errors.passwordConfirm && fmtFormError(errors.passwordConfirm.message)}
        <UpdateButton
          text="Submit"
          handlerFunc={handleSubmit(onSubmit)}
          disabled={!isValid}
        />
      </form>
    </div>
  );

  return (
    <div>
      <div className="landing-message">
        {resetRequestState === 'LINK_INVALID' && (
          <div>
            This link has expired. You can request a new link
            <Link className="underline" to="/forgot-password"> here</Link>.
          </div>
        )}
        {resetRequestState === 'LINK_VALID' && resetForm}
        {resetRequestState === 'RESET_SUCCESS' && (
          <p>
            Password change successful! Please log in again{' '}
            <Link className="underline" to="/login">
              here
            </Link>
            .
          </p>
        )}
        {resetRequestState === 'INIT' && 'Working...'}
      </div>
    </div>
  );
};

export default ResetPassword;
