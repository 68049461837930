import { connect } from 'react-redux';

import {
  getSaves,
  toggleSave,
  getAttendance,
  toggleAttendance,
} from '@happenings/components/post';
import { getComments } from '@happenings/components/entities/comments/actions';
import { updateFollow } from '@happenings/components/entities/follows';

import {
  openModal,
  closeModal,
  clearInviteUsersSearch,
} from '../../actions/ui_actions';

import { isUserAttendee, isUserLiker } from '../../reducers/selectors';
import { modalTypes as MODAL_TYPES } from '../../constants/modalTypes';

import PreviewPost from './PreviewPost';

const mapStateToProps = (state, ownProps) => ({
  currentUser: state.session ? state.session.currentUser : null,
  inviteModalOpen: !!state.ui.modals.inviteModal,
  attendees: state.entities.attendance[ownProps.id] || [],
  likers: state.entities.stars[ownProps.id] || [],
  currUserAttending: isUserAttendee(state, ownProps.id),
  currUserLiked: isUserLiker(state, ownProps.id),
  history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({
  updatePostStar: (postId, userId, value) =>
    dispatch(toggleSave(postId, userId, value)),
  updateAttendance: (postId, userId, value) =>
    dispatch(toggleAttendance(postId, userId, value)),
  updateFollow: (currUserId, followeeUsername, val) =>
    dispatch(updateFollow(currUserId, followeeUsername, val)),
  openInviteModal: (postId) =>
    dispatch(openModal(MODAL_TYPES.INVITE_MODAL, postId)),
  closeInviteModal: () => dispatch(closeModal(MODAL_TYPES.INVITE_MODAL)),
  init: (postId) => {
    dispatch(getComments(postId));
    dispatch(getSaves(postId));
    dispatch(getAttendance(postId));
  },
  clearSearch: () => dispatch(clearInviteUsersSearch()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PreviewPost);
