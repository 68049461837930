import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { updateFollow } from '@happenings/components/entities/follows';
import {
  selectUserFollowing, selectUserFollowers,
  isFollowing as selectCurrUserIsFollowing,
} from '@happenings/components/reducers/entities';
import { isUrl } from '../../utils';


const mapStateToProps = (state, ownProps) => ({
  currentUser: state.session.currentUser,
  profileUser: state.entities.users[ownProps.username],
  followers: selectUserFollowers(state, ownProps.username),
  following: selectUserFollowing(state, ownProps.username),
  isFollowingOrRequested: selectCurrUserIsFollowing(state, ownProps.username),
});

const mapDispatchToProps = dispatch => ({
  followUser: (followeeUsername, val) => (
    dispatch(updateFollow(followeeUsername, val))
  ),
});

const ProfileHeader = ({
  currentUser,
  profileUser,
  followers = [],
  following = [],
  isFollowingOrRequested = false,
  followUser,
}) => {
  const loggedIn = !!currentUser;
  const isCurrUser = (
    loggedIn && profileUser && currentUser.username === profileUser.username
  );

  let isPending = false;
  if (isFollowingOrRequested) {
    isPending = followers
      // TODO: this [0] is going to bite me in the future i guarantee it
      .filter(f => f.username === currentUser.username)[0].awaiting_approval;
  }

  const toggleFollow = () => {
    if (loggedIn) {
      const newVal = !isFollowingOrRequested;
      followUser(profileUser.username, newVal);
    }
  };

  let followBtnText = isFollowingOrRequested ? 'Following' : 'Follow';
  followBtnText = isPending ? 'Pending' : followBtnText;

  const settingsLink = (
    <Link to="/settings"><div className="follow-btn">Settings</div></Link>
  );
  const followBtn = (
    <div className="follow-btn" role="button" onClick={toggleFollow}>{followBtnText}</div>
  );
  const bigBtn = isCurrUser ? settingsLink : followBtn;

  // helper to add http protocol to url string if absent
  const linkHelper = url => ((!/^https?:\/\//i.test(url))
    ? `http://${url}`
    : url
  );

  // find links in bio string and make them clickable
  const renderBio = (bioText) => {
    if (bioText) { // safeguard against calling split() on null
      return bioText.split(' ').map((word, idx) => (
        isUrl(word)
          ? (
            <span key={`${word}-${idx}}`}>
              <a key={`${word}-${idx}`} className="bio-link" href={linkHelper(word)}>{word}</a>
              {' '}
            </span>
          )
          : <span key={`${word}-${idx}`}>{`${word} `}</span>
      ));
    }
    return null;
  };

  const followStats = (
    <Link to={`/user/${profileUser.username}/followers`} className="follow-stats">
      {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
      {followers.length} / {following.length}
    </Link>
  );

  return (
    <div className="profile-header-container">
      <div className="profile-header">
        <div className="item username">
          {profileUser.username}
        </div>
        <div className="item stats-container">
          {followStats}
          {bigBtn}
        </div>
      </div>

      <div className="profile-bio">
        {renderBio(profileUser.bio)}
      </div>
    </div>
  );
};

ProfileHeader.propTypes = {
  currentUser: PropTypes.shape({
    username: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  profileUser: PropTypes.shape({
    username: PropTypes.string,
    bio: PropTypes.string,
  }).isRequired,
  followers: PropTypes.arrayOf(
    PropTypes.shape({
      username: PropTypes.string,
    }),
  ),
  following: PropTypes.arrayOf(
    PropTypes.shape({
      username: PropTypes.string,
    }),
  ),
  isFollowingOrRequested: PropTypes.bool.isRequired,
  followUser: PropTypes.func.isRequired,
};

ProfileHeader.defaultProps = {
  followers: [],
  following: [],
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileHeader);
