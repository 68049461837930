import axios from 'axios';
import { put, takeLatest, call, select, takeEvery } from 'redux-saga/effects';

import { getFetchParams } from '@happenings/components/util';
import { getToken, getCurrUser } from '@happenings/components/session';
import { ApiAction } from '@happenings/components/common/types';
import * as commonTypes from '@happenings/components/constants/actionTypes';

import { actionTypes } from './index';

type WithUsername = ApiAction & {
  payload: { username: string },
};

export function* getFollowers({ payload }: WithUsername) {
  try {
    const token = yield select(getToken);
    const url = `${payload.url}/api/users/${payload.username}/followers`;
    const params = getFetchParams(token);
    yield put({ type: actionTypes.FETCH_FOLLOW_DATA });
    const res = yield call(axios.get, url, params);
    yield put({
      type: actionTypes.RECEIVE_FOLLOWER_DATA,
      username: payload.username,
      data: res.data,
    });
  } catch (e) {
    yield put({ type: commonTypes.RECEIVE_SESSION_ERRORS, statusCode: e?.response?.status });
  }
}

export function* getFollowing({ payload }: WithUsername) {
  try {
    const token = yield select(getToken);
    const url = `${payload.url}/api/users/${payload.username}/following`;
    const params = getFetchParams(token);
    yield put({ type: actionTypes.FETCH_FOLLOW_DATA });
    const res = yield call(axios.get, url, params);
    yield put({
      type: actionTypes.RECEIVE_FOLLOWING_DATA,
      username: payload.username,
      data: res.data,
    });
  } catch (e) {
    yield put({ type: commonTypes.RECEIVE_SESSION_ERRORS, statusCode: e?.response?.status });
  }
}

export function* updateFollow({ payload }: WithUsername) {
  try {
    const token = yield select(getToken);
    const currentUser = yield select(getCurrUser);
    const url = `${payload.url}/api/users/${payload.username}/followers?follower_id=${currentUser.id}`;

    const params = getFetchParams(token);
    yield put({ type: commonTypes.BEGIN_SERVER_UPDATE });
    if (payload.val) {
      yield call(axios.post, url, {}, params);
    } else {
      yield call(axios.delete, url, params);
    }
    // TODO: insert updated follow to store for optimistic update.

    // re-fetch data after server side update
    yield put({ type: 'GET_FOLLOWERS', payload });
    yield put({
      type: 'GET_FOLLOWING',
      payload: { ...payload, username: currentUser.username },
    });
    yield put({ type: commonTypes.SERVER_UPDATE_SUCCESS });
  } catch (e) {
    yield put({ type: commonTypes.RECEIVE_SESSION_ERRORS, statusCode: e?.response?.status });
  }
}

export function* approveFollow(
  { payload }: WithUsername & { payload: { followerName: string, val: boolean }},
) {
  try {
    const token = yield select(getToken);
    const currUsername = payload.username;
    const url = `${payload.url}/api/users/${currUsername}/followers?followerName=${payload.followerName}`;
    yield put({ type: commonTypes.BEGIN_SERVER_UPDATE });
    yield call(axios.put, url, {}, getFetchParams(token));
    yield put({ type: commonTypes.SERVER_UPDATE_SUCCESS });
  } catch (e) {
    yield put({ type: commonTypes.RECEIVE_SESSION_ERRORS });
  }
}

export default function* followSaga() {
  yield takeEvery(actionTypes.GET_FOLLOWERS, getFollowers);
  yield takeEvery(actionTypes.GET_FOLLOWING, getFollowing);
  yield takeLatest(actionTypes.UPDATE_FOLLOW, updateFollow);
  yield takeLatest(actionTypes.APPROVE_FOLLOW, approveFollow);
}
