import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import CopyEventLink from './CopyEventLink';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { openModal } from '../../actions/ui_actions';
import { modalTypes as MODAL_TYPES } from '../../constants/modalTypes';


const mapDispatchToProps = dispatch => ({
  openInviteModal: () => dispatch(openModal(MODAL_TYPES.INVITE_MODAL, true)),
});

const PostStats = ({
  postId,
  starCount,
  attendingCount,
  isStarred,
  isAttending,
  commentHandler,
  updateStar,
  updateAttending,
  }) => {
  const startBtnText = isStarred ? 'Saved!' : 'Save';
  const attendBtnTxt = isAttending ? 'Going!' : 'Attend';

  const attendBtnClass = classNames('post-btn attend', { 'active': isAttending });
  const starBtnClass = classNames('post-btn item', { 'active': isStarred });
  return (
    <div>
      <div className="post-stats-container">
         {/* <div className="stat-container">
          <div role="button" className="left-btn item" onClick={commentHandler}>Comment</div>
          <div role="button" className="left-btn item" onClick={openInviteModal}>Share</div>
        </div>
        <div className="stat-container">
          <CopyEventLink postId={postId} />
          <button
            className={starBtnClass}
            type="button"
            // toggles the current val of isStarred
            onClick={() => updateStar(isStarred)}
          >
            {startBtnText}
          </button>

          <button
            className={attendBtnClass}
            type="button"
            // toggles the current val of isAttending
            onClick={() => updateAttending(isAttending)}
          >
            {attendBtnTxt}
          </button>
        </div> */}
      </div>
      {/* <Link to={`/event/${postId}/attendance`} className="item">
        {`${attendingCount} attending, ${starCount} saved`}
      </Link> */}
    </div>
  );
};

PostStats.propTypes = {
  postId: PropTypes.number.isRequired,
  starCount: PropTypes.number.isRequired,
  attendingCount: PropTypes.number.isRequired,
  isStarred: PropTypes.bool.isRequired,
  commentHandler: PropTypes.func.isRequired,
  updateStar: PropTypes.func.isRequired,
  isAttending: PropTypes.bool.isRequired,
  updateAttending: PropTypes.func.isRequired,
  openInviteModal: PropTypes.func.isRequired,
};

export default connect(
  null,
  mapDispatchToProps,
)(PostStats);
