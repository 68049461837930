export const selectAuthoredPosts = (state, authorName) => {
  const res = {};
  const { posts } = state.entities.users[authorName];
  if (posts) {
    for (let i = 0; i < posts.length; i += 1) {
      const post = state.entities.posts[posts[i]];
      if (post) {
        res[posts[i]] = (post);
      }
    }
  }
  return res;
};

export const isUserAttendee = (state, postId) => {
  if (state.entities.attendance[postId] && !!state.session.currentUser) {
    const { username } = state.session.currentUser;
    return state.entities.attendance[postId]
      .map(e => e.username).includes(username);
  }
  return false;
};

export const isUserLiker = (state, postId) => {
  if (state.entities.stars[postId] && state.session.currentUser) {
    const { username } = state.session.currentUser;
    return state.entities.stars[postId]
      .map(e => e.username).includes(username);
  }
  return false;
};

export const selectOpenModal = (state) => {
  let retVal;
  Object.keys(state.ui.modals).forEach((key) => {
    // modals[key] is either a stringtype (postId) or boolean (false)
    if (state.ui.modals[key]) {
      retVal = key;
    }
  });
  return retVal;
};

export const selectUserResults = state => (
  state.ui.search.users
);

export const selectInviteUserResults = state => (
  state.ui.search.inviteUsers
);

export const selectPostResults = state => (
  state.ui.search.posts
);

export const countUnreadNotifications = (state) => {
  if (state.session.currentUser && !state.ui.loading.notificationsLoading) {
    const count = Object.keys(state.entities.notifications).filter(
      notification => state.entities.notifications[notification].read === false,
    ).length;
    return count;
  }
  return 0;
};

export const currentUserFollowing = (state) => {
  if (state.session.currentUser) {
    const { username } = state.session.currentUser;
    if (username in state.entities.follows) {
      if ('following' in state.entities.follows[username]) {
        return state.entities.follows[username].following
          .map(follow => follow.username);
      }
    }
  }
  return [];
};
