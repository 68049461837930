import { FetchAction } from '@happenings/components/common/types';

export const actionTypes = {
  REGISTER_NEW_USER: 'REGISTER_NEW_USER',
  SEND_VERIFICATION_CODE: 'SEND_VERIFICATION_CODE',
  VERIFY_PHONE_NUMBER: 'VERIFY_PHONE_NUMBER',
};

export const requestPasswordReset = (email: string): FetchAction => ({
  type: 'REQUEST_PASSWORD_RESET',
  payload: { email }
});

export const sendVerificationCode = (phone: string): FetchAction => ({
  type: actionTypes.SEND_VERIFICATION_CODE,
  payload: { phone }
});

export const verifyPhoneNumber = (phone: string, code: string): FetchAction => ({
  type: actionTypes.VERIFY_PHONE_NUMBER,
  payload: { phone, code }
});

export const registerNewUser = (
  username: string,
  email: string,
  password: string
): FetchAction => ({
  type: actionTypes.REGISTER_NEW_USER,
  payload: {
    username,
    email,
    password,
  },
});
