import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CheckBoxField from '../create/CheckBoxField';
import UpdateButton from '../util/UpdateButton';

class SearchUsersDropdown extends React.Component {
  state = { selectedUsers: [] }

  handleInviteUsers = async () => {
    try {
      const selectedUsersIDs = this.state.selectedUsers.map(u => u.id);
      await this.props.inviteUsers(this.props.postId, selectedUsersIDs);
      this.setState({ selectedUsers: [] });
      this.props.clearSearch();
      this.props.handleClose();
    } catch (err) {
      // TODO: standardize error handling
      console.error("Sorry, we couldn't invite those users.");
    }
  }

  handleCloseModal = () => {
    this.props.handleClose();
    this.props.clearSearch();
    this.props.closeModal();
  }

  handleSelectInvite(user) {
    const { selectedUsers } = this.state;
    // if the user is not yet selected, add them to the list of selected users
    if (!selectedUsers.some(u => u.id === user.id)) {
      this.setState({ selectedUsers: [...selectedUsers, user] });
    } else {
      // else, remove them from that state  var
      this.setState({
        selectedUsers: selectedUsers.filter(u => u.id !== user.id),
      });
    }
  }

  render() {
    const { selectedUsers } = this.state;
    const { userResults } = this.props;
    const userList = userResults ? userResults
      .filter(user => !this.state.selectedUsers.some(u => u.id === user.id))
      .map(user => (
        <li key={user.id}>
          <section className="post-result">
            <CheckBoxField
              id={user.id}
              field={null}
              label={user.username}
              handleChange={() => this.handleSelectInvite(user)}
            />
          </section>
        </li>
      )) : null;

    const selectedUsersList = selectedUsers.map(user => (
      <li key={user.id}>
        <section className="post-result">
          <CheckBoxField
            id={user.id}
            field={null}
            label={user.username}
            isSelected
            handleChange={() => this.handleSelectInvite(user)}
          />
        </section>
      </li>
    ));

    const hidden = classNames({
      'hidden': false,
    });


    return (
      <div style={{ height: '100%' }}>
        <section className={`invite-search ${hidden}`}>
          <ul className="results">
            {selectedUsersList}
            {userList}
          </ul>
        </section>


        <div className="post-actions-container">
          <div className="stat-container">
            <button
              className="post-btn item"
              type="button"
              style={{ minWidth: 52, height: 26 }}
              onClick={() => {
                // setModalIsOpen(post.id);
                this.handleCloseModal()
              }}
            >
              Cancel
            </button>

            <button
              className="post-btn item"
              type="button"
              style={{ minWidth: 52, height: 26 }}
              onClick={() => {
                this.handleInviteUsers();
              }}
            >
              Send Invites
            </button>
          </div>
        </div>

      </div>
    );
  }
}

SearchUsersDropdown.defaultProps = {
  userResults: [],
};

SearchUsersDropdown.propTypes = {
  userResults: PropTypes.arrayOf(PropTypes.object),
  inviteUsers: PropTypes.func.isRequired,
  postId: PropTypes.number.isRequired,
  closeModal: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default SearchUsersDropdown;
