import { FetchAction } from '@happenings/components/common/types';

export const actionTypes: Record<string, string> = {
  FETCH_FOLLOW_DATA: 'FETCH_FOLLOW_DATA',
  RECEIVE_FOLLOWER_DATA: 'RECEIVE_FOLLOWER_DATA',
  RECEIVE_FOLLOWING_DATA: 'RECEIVE_FOLLOWING_DATA',

  GET_FOLLOWERS: 'GET_FOLLOWERS',
  GET_FOLLOWING: 'GET_FOLLOWING',
  UPDATE_FOLLOW: 'UPDATE_FOLLOW',
  APPROVE_FOLLOW: 'APPROVE_FOLLOW',
};

/**
 * Action Creators
 */
export const getFollowers = (username: string): FetchAction => ({
  type: actionTypes.GET_FOLLOWERS,
  payload: { username },
});

export const getFollowing = (username: string): FetchAction => ({
  type: actionTypes.GET_FOLLOWING,
  payload: { username },
});

export const updateFollow = (followeeUsername: string, val: boolean): FetchAction => ({
  type: actionTypes.UPDATE_FOLLOW,
  payload: { username: followeeUsername, val },
});

export const approveFollow = (currUsername: string, followerName: string): FetchAction => ({
  type: actionTypes.APPROVE_FOLLOW,
  payload: { username: currUsername, followerName },
});
