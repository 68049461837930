import { all } from 'redux-saga/effects';

import sessionSaga from '@happenings/components/session/sagas';
import authSaga from '@happenings/components/auth/sagas';
import followSaga from '@happenings/components/entities/follows/sagas';
import feedSaga from '@happenings/components/feed/sagas';
import postSaga from '@happenings/components/post/sagas';
import inboxSaga from '@happenings/components/inbox/sagas';
import profileSaga from '@happenings/components/profile/sagas';
import searchSaga from '@happenings/components/search/sagas';
import createSaga from '@happenings/components/create/sagas';



export default function* rootSaga() {
  yield all([
    inboxSaga(),
    feedSaga(),
    sessionSaga(),
    profileSaga(),
    postSaga(),
    authSaga(),
    createSaga(),
    followSaga(),
    searchSaga(),
  ]);
}
