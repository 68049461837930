import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import InboxHeader from './InboxHeader';
import NavHeader from './NavHeader';
import SubPageHeader from './SubPageHeader';
import DefaultHeader from './DefaultHeader';

class Header extends React.Component {
  componentDidMount() {
    if (this.props.loggedIn) {
      this.props.getUserNotifications(this.props.currentUser.id);
    }
  }

  render() {
    if (this.props.loggedIn) {
      const { username } = this.props.currentUser;
      return (
        <Switch>
          <Route path="/inbox" component={InboxHeader} />
          <Route path="/event/:post_id" component={DefaultHeader} />
          <Route
            path="/settings"
            component={() => <SubPageHeader prevRoute={`/user/${username}`} />}
          />
          <Route
            path="/user/:username/followers"
            component={() => <SubPageHeader prevRoute={`/user/${username}`} />}
          />
          <Route
            path="/user/:username/following"
            component={() => <SubPageHeader prevRoute={`/user/${username}`} />}
          />
          <Route
            path="/"
            component={() => <NavHeader {...this.props} />}
          />
        </Switch>
      );
    }
    return <DefaultHeader prevRoute="/" />;
  }
}

Header.propTypes = {
  loggedIn: PropTypes.bool,
  currentUser: PropTypes.shape({
    username: PropTypes.string,
    id: PropTypes.number,
  }),
  getUserNotifications: PropTypes.func.isRequired,
};

Header.defaultProps = {
  currentUser: null,
  loggedIn: false,
};

export default Header;
