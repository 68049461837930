import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const DefaultHeader = ({ prevRoute = '/' }) => {
  return (
    <div className="header-container">
      <header className="header-default">
        <Link to={prevRoute}>
          <h1 className="logo">Happenings</h1>
        </Link>
      </header>
    </div>
  );
};

DefaultHeader.propTypes = {
  prevRoute: PropTypes.string.isRequired,
};

export default DefaultHeader;
