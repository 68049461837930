import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { approveFollow } from '@happenings/components/entities/follows';

import UpdateButton from '../util/UpdateButton';
import DeleteBtn from './DeleteBtn';

const mapDispatchToProps = dispatch => ({
  approve: (userName, followerName) => dispatch(approveFollow(userName, followerName)),
});

const FollowNotification = ({
  followerName,
  read,
  deleteNotification,
  markAsRead,
}) => {
  const classname = classNames('notification', { 'unread': !read });
  return (
    <div className={classname} role="button" onClick={markAsRead}>
      <div className="notification-title">
        <Link to={`/user/${followerName}`}>{followerName}</Link>
        <span>{' followed you'}</span>
        <DeleteBtn deleteNotification={deleteNotification} />
      </div>
    </div>
  );
};

const followRequestNotification = ({
  userName,
  followerName,
  read,
  deleteNotification,
  markAsRead,
  approve,
}) => {
  const classname = classNames('notification', { 'unread': !read });
  const approveHandler = () => {
    approve(userName, followerName);
    deleteNotification();
  };

  return (
    <div className={classname} role="button" onClick={markAsRead}>
      <div className="notification-title">
        <Link to={`/user/${followerName}`}>{followerName}</Link>
        <span>{' wants to follow you'}</span>
        <UpdateButton
          className="action-btn"
          text="Approve"
          handlerFunc={approveHandler}
        />
        <DeleteBtn deleteNotification={deleteNotification} />
      </div>
    </div>
  );
};

export const FollowRequestNotification = connect(
  null,
  mapDispatchToProps,
)(followRequestNotification);


const basePropTypes = {
  followerName: PropTypes.string.isRequired,
  read: PropTypes.bool.isRequired,
  deleteNotification: PropTypes.func.isRequired,
  markAsRead: PropTypes.func.isRequired,
};

FollowNotification.propTypes = basePropTypes;

followRequestNotification.propTypes = {
  ...basePropTypes,
  userName: PropTypes.string.isRequired,
  followerName: PropTypes.string.isRequired,
  approve: PropTypes.func.isRequired,
};

export default FollowNotification;
