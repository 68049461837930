import React from 'react';
import { connect } from 'react-redux';
import { toggleBar } from '../../actions/ui_actions';

import magnifyingGlass from '../../assets/magnifying_glass.svg'


const SearchToggle = ({ handleToggleBar, barOpen }) => {
  return !barOpen
    ? <img alt="magnifying-glass" onClick={handleToggleBar} src={magnifyingGlass} />
    : <div style={{ width: 15 }} />;
};

const mapStateToProps = state => ({
  barOpen: state.ui.search.barOpen,
});

const mapDispatchToProps = dispatch => ({
  handleToggleBar: () => dispatch(toggleBar()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchToggle);
