import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const mapStateToProps = state => ({
  updateInProgress: state.ui.loading.updateInProgress,
});

export const UpdateButtonBase = ({ className, text, handlerFunc, updateInProgress, disabled }) => {
  // don't let handlerFunc be called if update is in progress
  const [wasClicked, setWasClicked] = useState(false);

  const canClick = !updateInProgress && !disabled;
  const clickHandler = (e) => {
    setWasClicked(true);
    if (canClick) {
      return handlerFunc(e);
    }
    return undefined;
  };
  const buttonUpdateInProgress = wasClicked && updateInProgress;
  const buttonClass = className || 'submit-btn';
  return (
    <input
      style={{ paddingInline: '7px' }}
      className={classNames(buttonClass, { 'disabled': buttonUpdateInProgress || disabled })}
      type="submit"
      disabled={disabled}
      onClick={clickHandler}
      value={!buttonUpdateInProgress ? text : 'working...'}
    />
  );
};

UpdateButtonBase.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  handlerFunc: PropTypes.func.isRequired,
  updateInProgress: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

UpdateButtonBase.defaultProps = {
  className: '',
  disabled: false,
  updateInProgress: false,
};

export default connect(
  mapStateToProps,
  null,
)(UpdateButtonBase);
