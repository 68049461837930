// session actions
export const FETCH_WITH_AUTH = 'FETCH_WITH_AUTH';
export const RECEIVE_CURRENT_USER = 'RECEIVE_CURRENT_USER';
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER';
export const PROCEED_WITHOUT_USER_LOGIN = 'PROCEED_WITHOUT_USER_LOGIN';
export const RECEIVE_SESSION_ERRORS = 'RECEIVE_SESSION_ERRORS';
export const CLEAR_SESSION_ERRORS = 'CLEAR_SESSION_ERRORS';

// ui actions
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

// user actions
export const BEGIN_USER_FETCH = 'BEGIN_USER_FETCH';
export const RECEIVE_USER = 'RECEIVE_USER';
export const UPDATE_FOLLOW_VAL = 'UPDATE_FOLLOW_VAL';
export const RECEIVE_FOLLOWER_COUNT = 'RECEIVE_FOLLOWER_COUNT';
export const RECEIVE_FOLLOWING_COUNT = 'RECEIVE_FOLLOWING_COUNT';
export const RECEIVE_FOLLOWER_DATA = 'RECEIVE_FOLLOWER_DATA';
export const RECEIVE_FOLLOWING_DATA = 'RECEIVE_FOLLOWING_DATA';

// loading actions
export const RECEIVE_FEED_PAGE = 'RECEIVE_FEED_PAGE';
export const RECEIVE_PROFILE_POSTS = 'RECEIVE_PROFILE_POSTS';
export const RECEIVE_DISCOVER_FEED_PAGE = 'RECEIVE_DISCOVER_FEED_PAGE';
export const BEGIN_PAGE_FETCH = 'BEGIN_PAGE_FETCH';
export const BEGIN_CURR_USER_INFO_FETCH = 'BEGIN_CURR_USER_INFO_FETCH';
export const BEGIN_EVENT_UPLOAD = 'BEGIN_EVENT_UPLOAD';
export const COMPLETE_EVENT_UPLOAD = 'COMPLETE_EVENT_UPLOAD';
export const BEGIN_SERVER_UPDATE = 'BEGIN_SERVER_UPDATE';
export const SERVER_UPDATE_SUCCESS = 'SERVER_UPDATE_SUCCESS';

// search actions
export const RECEIVE_SEARCHED_USERS = 'RECEIVE_SEARCHED_USERS';
export const RECEIVE_INVITE_SEARCHED_USERS = 'RECEIVE_INVITE_SEARCHED_USERS';
export const RECEIVE_SEARCHED_POSTS = 'RECEIVE_SEARCHED_POSTS';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';
export const CLEAR_INVITE_USERS_SEARCH = 'CLEAR_INVITE_USERS_SEARCH';
export const UPDATE_SEARCHED_ENTITY = 'UPDATE_SEARCHED_ENTITY';
export const TOGGLE_BAR = 'TOGGLE_BAR';
export const CLOSE_SEARCH_BAR = 'CLOSE_SEARCH_BAR';

// inbox actions
export const BEGIN_INBOX_FETCH = 'BEGIN_INBOX_FETCH';
export const RECEIVE_INBOX_EVENTS = 'RECEIVE_INBOX_EVENTS';
export const BEGIN_NOTIFICATION_FETCH = 'BEGIN_NOTIFICATION_FETCH';
export const RECEIVE_NOTIFICATION_PAGE = 'RECEIVE_NOTIFICATION_PAGE';
export const MARK_NOTIFICATION_AS_READ = 'MARK_NOTIFICATION_AS_READ';
export const CREATE_NOTIFICATION_SUCCESS = 'CREATE_NOTIFICATION_SUCCESS';

// single post actions
export const BEGIN_POST_FETCH = 'BEGIN_POST_FETCH';
export const RECEIVE_POST_DATA = 'RECEIVE_POST_DATA';
export const RECEIVE_POST_STARS = 'RECEIVE_POST_STARS';
export const RECEIVE_POST_ATTENDNACE = 'RECEIVE_POST_ATTENDNACE';
export const RECEIVE_POST_COMMENTS = 'RECEIVE_POST_COMMENTS';

// image file actions
export const BEGIN_IMAGE_UPLOAD = 'BEGIN_IMAGE_UPLOAD';
export const IMAGE_UPLOAD_SUCCESS = 'IMAGE_UPLOAD_SUCCESS';

// router actions
export const ROUTE_CHANGE = 'ROUTE_CHANGE';
export const CANCEL_PAGE_FETCH = 'CANCEL_PAGE_FETCH';
