import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { deleteComment, getComments } from '@happenings/components/entities/comments/actions';

import Comment from './Comment';
import ScrollLoader from '../common/ScrollLoader';
import LoadingIcon from '../common/LoadingIcon';

const mapStateToProps = (state, props) => ({
  currentUser: state.session.currentUser,
  comments:
    state.entities.comments[parseInt(props.match.params.event_id)]?.comments ||
    [],
  loading: state.ui.loading.commentsLoading,
});

const mapDispatch = {
  deleteComment,
  getComments,
}

const CommentPage = (props) => {
  const postId = parseInt(props.match.params.event_id);
  const [commentToDelete, setCommentToDelete] = useState(null);
  const deleteCommentHelper = (commentId) => {
    props.deleteComment(postId, commentId);
    setCommentToDelete(null);
  };

  const renderComment = (comment) => {
    return (
      <Comment
        key={comment.id}
        id={comment.id}
        username={comment.username}
        text={comment.text}
        userIsAuthor={
          !props.currentUser || comment.username === props.currentUser.username
        }
        commentToDelete={commentToDelete}
        deleteCommentCallback={setCommentToDelete}
      />
    );
  };
  const renderComments = () => {
    return props.comments.map(renderComment);
  };

  return (
    <div className="comments-container">
      {renderComments()}
      <ScrollLoader
        onEnter={() => props.getComments(postId)}
        isLoading={props.loading}
      />
      {props.loading && <LoadingIcon />}
      {commentToDelete !== null ? (
        <button
          type="button"
          onClick={() => deleteCommentHelper(commentToDelete)}
          className="delete-button"
        >
          Delete Comment
        </button>
      ) : null}
    </div>
  );
};

CommentPage.propTypes = {
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.text,
      username: PropTypes.text,
      text: PropTypes.text,
    })
  ).isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
  }),
  deleteComment: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  getComments: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      event_id: PropTypes.string,
    }),
  }).isRequired,
};

CommentPage.defaultProps = {
  currentUser: null,
};

export default withRouter(
  connect(mapStateToProps, mapDispatch)(CommentPage)
);
