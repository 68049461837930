import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { closeModal } from '../../actions/ui_actions';
import { deleteUserAccount } from '@happenings/components/user';
import { logoutUser } from '@happenings/components/session';
import { modalTypes as MODAL_TYPES } from '../../constants/modalTypes';
import DeleteAccountModal from './DeleteAccountModal';

const mapStateToProps = state => ({
  currentUser: state.session.currentUser,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal(MODAL_TYPES.DELETE_ACCOUNT_MODAL)),
  deleteUserAccount: userId => dispatch(deleteUserAccount(userId)),
  clearCookies: () => dispatch(logoutUser()),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteAccountModal));
