// messages for signup form validation
import * as actionTypes from '@happenings/components/constants/actionTypes';
import { MIN_PASSWORD_LENGTH } from '@happenings/components/constants';

export const USERNAME_FMT_MSG = 'username must be lowercase alphanumeric.';
export const PASSWORD_FMT_MSG = `password must be at least ${MIN_PASSWORD_LENGTH} characters`;

export const API_USERNAME_UNIQUE_MSG = 'username already taken';
export const API_EMAIL_UNIQUE_MSG = 'email already taken';
export const API_LOGIN_ERR_MSG = 'username and/or password incorrect';

export const USERNAME_UNAVAILABLE = 'username unavailable';
export const EMAIL_UNAVAILABLE = 'an account with this email already exists';

export enum ErrorCode {
  DEFAULT = '',
  USERNAME_UNAVAILABLE = 'username_unavailable',
  EMAIL_UNAVAILABLE = 'email_unavailable',
  LOGIN_ERROR = 'login_error',
  EVENT_NOT_FOUND = 'event_not_found',
}

export interface Errors {
  error: string;
  exception?: string;

  // TODO deprecate
  statusCode?: number;
  errorCode: ErrorCode;
}

const DEFAULT_STATE: Errors = {
  error: '',
  exception: '',
  errorCode: ErrorCode.DEFAULT
};

interface Action {
  type: string;
  error: string;
  statusCode: number;
  errorCode: ErrorCode;
}
export const receiveSessionErrors = (
  error: string,
  statusCode: number
): Action => ({
  type: actionTypes.RECEIVE_SESSION_ERRORS,
  error,
  statusCode,
  errorCode: ErrorCode.DEFAULT
});


export const clearSessionErrors = () => ({
  type: actionTypes.CLEAR_SESSION_ERRORS,
});

const errorsReducer = (state = DEFAULT_STATE, action: Action): Errors => {
  switch (action.type) {
    case 'BEGIN_PAGE_FETCH':
      return { ...state, errorCode: ErrorCode.DEFAULT };
    case 'SET_ERROR_CODE':
      return { ...state, errorCode: action.errorCode }
    case actionTypes.RECEIVE_SESSION_ERRORS:
      return { ...state, error: action.error, statusCode: action.statusCode };
    case actionTypes.CLEAR_SESSION_ERRORS:
      return DEFAULT_STATE;
    // errors should not hang around across route changes
    case actionTypes.ROUTE_CHANGE:
      return DEFAULT_STATE;
    default:
      return state;
  }
};

export default errorsReducer;
