import {
  ADD_COMMENT,
  RECEIVE_COMMENTS,
  DELETE_COMMENT,
  CommentActionTypes,
  CLEAR_COMMENTS,
  Comment,
} from './types';
import {
  PageState,
  paginator,
  PagingStrategies,
  DEFAULT_STATE as defaultPageState,
} from '@happenings/components/pagination';

export type PerPostState = {
  comments: Comment[];
  pagination: PageState;
};

const DEFAULT_STATE: PerPostState = {
  comments: [],
  pagination: defaultPageState,
};

// store comments and pagination state for each postID
export type State = Record<number, PerPostState>;
const INITIAL_STATE: State = {};

const comments = (state = INITIAL_STATE, action: CommentActionTypes): State => {
  switch (action.type) {
    // When adding a comment, set the pagination 'hasMore' to true
    // to reflect the fact that a new comment was created at the server.
    case ADD_COMMENT:
      return {
        ...state,
        [action.payload.postId]: {
          ...state[action.payload.postId],
          pagination: state[action.payload.postId]
          ? {
              ...state[action.payload.postId].pagination,
              hasMore: true,
            }: defaultPageState,
        },
      };
    case RECEIVE_COMMENTS:
      return {
        ...state,
        [action.payload.postId]: {
          comments: state[action.payload.postId]
            ? [
                ...state[action.payload.postId].comments,
                ...action.payload.comments,
              ]
            : action.payload.comments,
          pagination: paginator(
            state[action.payload.postId]?.pagination?.lastEvaluatedKey || null,
            state[action.payload.postId]?.pagination?.keys || [],
            action.payload.comments.map((c: Comment) => c.id),
            PagingStrategies.OLDEST_FIRST
          ),
        },
      };
    case DELETE_COMMENT:
      return {
        ...state,
        [action.payload.postId]: {
          ...state[action.payload.postId],
          comments: state[action.payload.postId].comments.filter(
            (comment) => comment.id !== action.payload.commentId
          ),
        },
      };
    case CLEAR_COMMENTS:
      return {
        ...state,
        [action.payload.postId]: DEFAULT_STATE
      };
    default:
      return state;
  }
};

export default comments;
