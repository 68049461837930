/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Link } from 'react-router-dom';

import SignUpFormContainer from './auth/SignUpFormContainer';
import WaitlistSignup from './auth/WaitlistSignup';

const PublicLanding = () => {
  return (
    <div className="public-landing-container">
      <WaitlistSignup />
    </div>
  );
};

export default PublicLanding;
