import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SearchUsersDropdownContainer from '../menus/SearchUsersDropdownContainer';

class SearchUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.executeQuery = this.executeQuery.bind(this);
    this.handleEsc = this.handleEsc.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  executeQuery() {
    if (this.state.query.length > 0) {
      this.props.searchUsers(this.state.query, this.props.postId);
    }
  }

  handleEsc(e) {
    if (e.key === 'Escape') {
      this.setState({ query: '' });
    }
  }

  handleClose() {
    this.props.onClose();
    this.setState({ query: '' });
  }


  handleChange(e) {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
    }
    this.setState({ query: e.currentTarget.value }, () => {
      this.timeOut = setTimeout(this.executeQuery, 300);
    });
  }

  render() {
    const containerClass = classNames('search-container', { folded: !this.props.barOpen })

    return (
      <div className={containerClass} style={{ height: '80%' }}>
        <div className="invite-search-field-container">
          <input
            className="invite-search-field"
            type="text"
            placeholder="Search users"
            onChange={this.handleChange}
            onKeyDown={this.handleEsc}
            onClick={e => e.stopPropagation()}
            value={this.state.query}
          />

          <div className="invite-search-offset-box" />
        </div>
        <SearchUsersDropdownContainer
          searchQuery={this.state.query}
          handleClose={this.handleClose}
          postId={this.props.postId}
        />
      </div>
    );
  }
}

SearchUsers.propTypes = {
  searchUsers: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  postId: PropTypes.number.isRequired,
};

export default SearchUsers;
