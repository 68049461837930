import React from 'react';
import PropTypes from 'prop-types';

import {
  GRANULARITY_OPTIONS, GRANULARITY_DISPLAY_NAMES } from '../../constants';

import KeySelector from '../util/KeySelector';

const GranularitySelector = ({ selection, onChange }) => {
  return (
    <KeySelector
      keyList={GRANULARITY_OPTIONS}
      containerClass="granularity-selector"
      btnNameMap={GRANULARITY_DISPLAY_NAMES}
      btnClass="granularity-btn"
      selection={selection}
      onChange={onChange}
    />
  );
};

GranularitySelector.propTypes = {
  selection: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default GranularitySelector;
