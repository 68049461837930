import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getPostData } from '@happenings/components/post';
import PostPage from './PostPage';


const mapStateToProps = (state, ownProps) => ({
  loggedIn: !!state.session.currentUser,
  loaded: !state.ui.loading.postsLoading,
  post: state.entities.posts[ownProps.match.params.event_id],
  error: state.errors,
  postNotFound: state.errors.statusCode >= 400,
});

const mapDispatchToProps = dispatch => ({
  getPostData: postId => dispatch(getPostData(postId)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PostPage),
);
