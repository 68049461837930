import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import classNames from 'classnames';

const CopyEventLink = (props) => {
  const [isCopied, setIsCopied] = useState(false);

  const eventURL = `${window.location.host}/event/${props.postId}`;
  const copyText = isCopied ? 'Copied!' : 'Copy';
  return (
    <button
      className={classNames('post-btn item', { 'active': isCopied })}
      type="button"
    >
      <div>
        <CopyToClipboard
          text={eventURL}
          onCopy={() => setIsCopied(true)}
        >
          <span>{copyText}</span>
        </CopyToClipboard>
      </div>
    </button>
  );
};

export default CopyEventLink;
