import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import React from 'react';
import PropTypes from 'prop-types';
import { conjugate } from '@happenings/components/feed';

import ScrollLoader from '../common/ScrollLoader';
import PostContainer from '../post/PostContainer';
import LoadingIcon from '../common/LoadingIcon';


const DISCOVER_FEED_NAME = 'discover';


const mapStateToProps = state => ({
  postIDs: state.feed.postIDs,
  posts: state.entities.posts,
  loading: state.ui.loading.postsLoading,
});

const mapDispatchToProps = dispatch => ({
  getFeed: () => dispatch({ type: 'GET_FEED', feedName: DISCOVER_FEED_NAME }),
});


export const DiscoverFeed = ({ postIDs, posts, loading, getFeed }) => {
  const addPosts = () => {
    const discoverPosts = postIDs.map((postId) => {
      const postData = posts[postId];
      const { friendInfo } = postData;
      if (friendInfo) {
        const { interactionType, followee } = friendInfo;
        const feedMsg = conjugate(interactionType, followee, postData.username);

        return (
          <div key={postId}>
            {feedMsg}
            <PostContainer
              id={postId}
              post={postData}
            />
          </div>
        );
      }
      return null;
    });

    return discoverPosts;
  };

  const noPosts = Object.keys(posts).length === 0;
  const noDiscoverMsg = 'nobody you follow has done anything! follow cooler people!';
  return (
    <div className="main-feed-container">
      { addPosts() }
      <ScrollLoader onEnter={getFeed} isLoading={loading} />
      { loading && <LoadingIcon /> }
      {
        noPosts
        && !loading
        && noDiscoverMsg
      }
    </div>
  );
};

DiscoverFeed.propTypes = {
  getFeed: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  postIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
  posts: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(DiscoverFeed));
