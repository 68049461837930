import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Page404 from './Page404';
import Header from '../header/SubPageHeader';

const mapStateToProps = state => ({
  errors: state.errors,
});

const Page500 = () => (
  <div className="error-page-500">
    <div className="message">
      <span className="header">500</span>
      <p>
        Something went wrong on our end...
        <br />
        please reload the page and try again
      </p>
    </div>
  </div>
);

// 404 page with a header so user can nav back to landing
const NotFoundPage = () => (
  <div>
    <Header />
    <Page404 />
  </div>
);

const ApiErrorHandler = ({ errors }) => {
  if (errors.statusCode === 500) {
    return <Page500 />;
  }
  if (errors.statusCode >= 400) {
    return <NotFoundPage />;
  }
  return null;
};

ApiErrorHandler.propTypes = {
  errors: PropTypes.shape({
    statusCode: PropTypes.number,
  }).isRequired,
};

export default connect(
  mapStateToProps,
  null,
)(ApiErrorHandler);
