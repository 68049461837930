import axios from 'axios';
import { put, takeLatest, call } from 'redux-saga/effects';

import { ApiAction, ApiPayload } from '@happenings/components/common/types';
import { getNotifications } from '@happenings/components/inbox';

import {
  actionTypes as sessionTypes,
  API_LOGIN_ERR_MSG,
} from '@happenings/components/session';
import { getFetchParams } from '@happenings/components/util';

import { CurrentUser } from './index';
import * as types from '../constants/actionTypes';
import { fetchUserByUsername } from '../user';

export type initPayload = ApiPayload & {
  history: History;
  authRequired: boolean;
  token?: string;
};

export function* initSession({
  payload,
}: {
  type: string;
  payload: initPayload;
}) {
  try {
    const url = `${payload.url}/api/auth/extend`;
    // TODO: figure out why this works
    let params = { ...getFetchParams(payload?.token), credentials: 'include' };
    yield put({ type: types.BEGIN_CURR_USER_INFO_FETCH });
    const res = yield call(axios.get, url, params);
    yield put({ type: types.RECEIVE_CURRENT_USER, currentUser: res.data });
    if (res.data.username) yield put(fetchUserByUsername(res.data.username));
  } catch (e) {
    // case when auth not required for session
    if (e.response && e.response.status === 400) {
      yield put({
        type: types.PROCEED_WITHOUT_USER_LOGIN,
        response: e.response,
      });
    } else {
      yield put({
        type: types.RECEIVE_SESSION_ERRORS,
        error: e,
        statusCode: 500,
      });
    }
  }
}

export function* logOut({ payload }: ApiAction) {
  try {
    yield put({ type: types.CLEAR_CURRENT_USER });
    // API call to unset the auth cookie
    const url = `${payload.url}/api/auth/logout`;
    yield call(axios.get, url);
  } catch (e) {
    yield put({ type: types.RECEIVE_SESSION_ERRORS, error: e.response });
  }
}

interface loginPayload {
  username: string;
  password: string;
}
export function* loginUser({
  payload,
}: {
  type: string;
  payload: loginPayload & ApiPayload;
}) {
  try {
    yield put({ type: types.BEGIN_SERVER_UPDATE });
    const url = `${payload.url}/api/auth/login`;
    const params = {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };
    const { username, password } = payload;
    const res = yield call(axios.post, url, { username, password }, params);
    const currentUser: CurrentUser = res.data;
    yield put({ type: types.RECEIVE_CURRENT_USER, currentUser });
    yield put({ type: types.SERVER_UPDATE_SUCCESS });

    // after successful login, get user notifications
    yield put(getNotifications());
  } catch (e) {
    if (e.response && e.response.status === 400) {
      yield put({
        type: types.RECEIVE_SESSION_ERRORS,
        error: API_LOGIN_ERR_MSG,
      });
    } else {
      yield put({
        type: types.RECEIVE_SESSION_ERRORS,
        error: e.response,
        statusCode: 500,
      });
    }
  }
}

export default function* main() {
  yield takeLatest(sessionTypes.LOGIN_USER, loginUser);
  yield takeLatest(sessionTypes.LOGOUT_USER, logOut);
  yield takeLatest(sessionTypes.INIT_SESSION, initSession);
}
