import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Redirect } from 'react-router-dom';

import { formDataFromPost } from '@happenings/components/create';

import ImageHandler from './ImageHandler';
import UpdateButton from '../util/UpdateButton';
import LoadingIcon from '../common/LoadingIcon';
import DatePicker from './DatePicker';
import TimePicker from './TimePicker';
import EventCheckboxForm from './EventCheckboxForm';

import { AGES_ALL } from '../../constants/ageRestrictions';

const INITIAL_FIELD_STATES = {
  title: '',
  location: '',
  description: '',
  isPrivate: false,
  isAccessible: false,
  guestsCanInvite: false,
  date: null,
  hour: '12',
  minute: '00',
  amOrPm: 'AM',
  ageRestriction: 'AGES_ALL',
};
/**
 * EditPostPage
 *
 * Used for both creating new events
 * as well as editing existing events :)
 */

const EditPostPage = (props) => {
  let formData = INITIAL_FIELD_STATES;
  const { posts, postId, newlyCreatedPostId } = props;
  const post = posts ? posts[postId] : undefined;

  useEffect(() => {
    formData = post ? formDataFromPost(post) : INITIAL_FIELD_STATES;
  }, [props.posts]);

  const defaultValues = {
    date: formData.date || null,
    isPrivate: formData.isPrivate || false,
    guestsCanInvite: formData.guestsCanInvite || false,
    isAccessible: formData.isAccessible || false,
    ageRestriction: formData.ageRestriction || AGES_ALL,
  };

  const { register, handleSubmit, setValue, watch, errors } = useForm({ defaultValues });

  useEffect(() => {
    props.clearFormFields();
    if (!props.isNewEvent && !props.post) { props.initForm(postId); }
  }, [postId]);

  // custom registration of controlled form fields
  useEffect(() => {
    register({ name: 'date' }, { validate: v => !!v || 'event date is required' });
    register({ name: 'isPrivate' });
    register({ name: 'guestsCanInvite' });
    register({ name: 'isAccessible' });
    register({ name: 'ageRestriction' });
    register({ name: 'hour' });
    register({ name: 'minute' });
    register({ name: 'amOrPm' });
  }, [register]);

  // get references to controlled form fields that have been registered
  const {
    date, isPrivate, guestsCanInvite, isAccessible, ageRestriction, hour, minute, amOrPm,
  } = watch();

  // listen on formData changes (e.g. when initial post data fetch completes),
  // update form state accordingly
  useEffect(() => {
    setValue('title', formData.title);
    setValue('location', formData.location);
    setValue('description', formData.description);
    setValue('ageRestriction', formData.ageRestriction);
    setValue('isAccessible', formData.isAccessible);
    setValue('guestsCanInvite', formData.guestsCanInvite);
    setValue('isPrivate', formData.isPrivate);
    setValue('date', formData.date);
    setValue('hour', formData.hour);
    setValue('minute', formData.minute);
    setValue('amOrPm', formData.amOrPm);
  }, [props.posts]);

  const onSubmit = (data) => {
    if (props.isNewEvent) {
      props.createEvent(data);
    } else {
      props.updateEvent(postId, data);
    }
  };

  const { isNewEvent, imgLoaded, eventImageCleared } = props;

  const btnDisableCondition = (isNewEvent && !imgLoaded)
    || (!isNewEvent && eventImageCleared && !imgLoaded)
    ;

  const receivedNewlyCreatedPost = newlyCreatedPostId !== null;
  if (receivedNewlyCreatedPost) {
    return (
      <Redirect to={`/event/${newlyCreatedPostId}`} />
    )
  }

  const requiredMsg = <span className="form-error">required</span>;
  return props.loaded ? (
    <div className="create-container">
      {`${props.isNewEvent ? 'Create' : 'Edit'} your event`}
      <div className="event-info-form">
        <form className="form" onSubmit={() => {}}>
          <input
            name="title"
            placeholder="Title"
            defaultValue={formData.title}
            ref={register({ required: 'required field' })}
          />
          { errors.title && requiredMsg }
          <input
            name="location"
            placeholder="Location"
            defaultValue={formData.location}
            ref={register()}
          />
          {/* n.b. controlled form field */}
          <DatePicker
            date={date}
            onDateChange={d => setValue('date', d)}
          />
          { errors.date && requiredMsg }
          <TimePicker
            hour={hour}
            minute={minute}
            amOrPm={amOrPm}
            updateFormField={(fieldName, val) => setValue(fieldName, val)}
            isEmpty={props.isNewEvent === true}
          />
          <textarea
            name="description"
            placeholder="Additional details"
            defaultValue={formData.description}
            ref={register()}
          />
          {/* n.b. wrapper component around controlled form checkbox fields */}
          <EventCheckboxForm
            formData={{ isPrivate, guestsCanInvite, isAccessible, ageRestriction }}
            handleChange={(fieldName, isSelected) => setValue(fieldName, isSelected)}
          />
          Poster image
          <ImageHandler
            showPreview={!(props.eventImageCleared || props.isNewEvent)}
            imgUrl={post ? post.publicUrl : ''}
          />
          <UpdateButton
            disabled={btnDisableCondition}
            text={props.isNewEvent ? 'Create' : 'Save Changes'}
            handlerFunc={handleSubmit(onSubmit)}
          />
        </form>
      </div>
    </div>
  ) : <LoadingIcon />;
};

EditPostPage.propTypes = {
  loaded: PropTypes.bool,
  isNewEvent: PropTypes.bool.isRequired,
  imgLoaded: PropTypes.bool.isRequired,
  clearFormFields: PropTypes.func.isRequired,
  initForm: PropTypes.func.isRequired,
  createEvent: PropTypes.func.isRequired,
  updateEvent: PropTypes.func.isRequired,

  post: PropTypes.shape({
    publicUrl: PropTypes.string,
  }),

  eventImageCleared: PropTypes.bool.isRequired,

  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,

  match: PropTypes.shape({
    params: PropTypes.shape({
      event_id: PropTypes.string,
    }),
  }).isRequired,
};

EditPostPage.defaultProps = {
  loaded: false,
  post: null,
};

export default EditPostPage;
