import { connect } from 'react-redux';
import { registerNewUser } from './index';

import SignUpForm from './SignUpForm';

const mapDispatchToProps = dispatch => ({
  registerNewUser: (username, email, password) => (
    dispatch(registerNewUser(username, email, password))
  ),
});

export default connect(
  null,
  mapDispatchToProps,
)(SignUpForm);
