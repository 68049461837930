import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import PostContainer from '../post/PostContainer';

const mapStateToProps = (state) => ({
  postsById: state.entities.posts,
});

const PostFeed = ({ postIDs, postsById }) => {
  const postFeed = postIDs.map((postId) => {
    const post = postsById[postId];
    return <PostContainer key={postId} id={postId} post={post} />;
  });
  return postFeed;
};

PostFeed.propTypes = {
  postsById: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  postIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default connect(mapStateToProps, null)(PostFeed);
