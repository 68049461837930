
import React from 'react';
import LegalPageHeader from './LegalPageHeader';

import html from './privacy-policy';

const About = () => {
  return (
    <div className="legal-page about">
      <LegalPageHeader title={'About'} />
      <div className="text-content">
        <p>
            For social and community organizing—From performances to art openings, protests to teach-ins, readings to lectures, parties to group hangs. Public or private. Physical or virtual 
            A tool to help people organize, experience, enjoy, and learn.
        </p>

        <p>
            Stay engaged with and connected to your communities. ︎︎︎Happenings is here to help people and places be active and involved through in-person and virtual events.
        </p>

        <section>
            <h3>THE DESIRED ALTERNATIVE</h3>
            <p>
                We came together as a group of artists, organizers, and technologists to build a simple platform that 
                we all felt was missing. There wasn’t a single destination dedicated to sharing, discovering, and keeping 
                track of both public and private events.Invites and event-related posts are often lost in cluttered feeds, 
                group texts, inactive social media accounts, and overwhelmed email inboxes. We’ve created a platform that 
                makes it easy to share and discover happenings in our communities—a tool that’s equally useful for attendees and hosts.
            </p>
            <p>
                At the same time, we want to offer people the freedom to disengage from perpetual social media- use while staying in the 
                know about goings-on. Happenings is a desired alternative to platforms that operate with values that contradict our own. 
                We prioritize people and their privacy over profit. We reject algorithmic amplification. We are against surveillance 
                capitalism and personal data collection. We are committed to inclusivity, accessibility, sustainability and anti-racism.
            </p>
        </section>

        <section>
            <h3>HOW IT WORKS</h3>
            <p>Follow the people and places that organize events and programs you wish to engage with—friends, cultural institutions, community organizers, local bookstores, venues, etc.</p>
            <p>No ads, likes, or irrelevent recommendations. What appears before you is generated by you and the places and people you follow..</p>
        </section>

        <section>
            <h3>Create</h3>
            <p>Design a sharable visual invite by making a poster using the poster-feature or upload a pre-exisiting image to create an event.</p>
        </section>

        <section>
            <h3>Discover</h3>
            <p>Use the  feed to see events (PUBLIC and PRIVATE) posted by people and places you follow.</p>
        </section>

        <section>
            <h3>Share</h3>
            <p>
                Share Invite people in your community to your own public or private events. Share events that appear in your feeds with friends. Copy an event link and text it.
            </p>
        </section>

        <section>
            <h3>Organize</h3>
            <p>Keep track of up-coming events you’ve saved in your feed, manage upcoming events you’re hosting, look back fondly on the past ones.</p>
        </section>

      </div>
    </div>
  );
};

export default About;
