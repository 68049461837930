/* eslint-disable semi */
import {
  authFetch,
  handleResponse,
} from './common_actions';
import * as types from '../constants/actionTypes';

export const receiveSearchedUsers = users => ({
  type: types.RECEIVE_SEARCHED_USERS,
  users,
});

export const receiveInviteSearchedUsers = users => ({
  type: types.RECEIVE_INVITE_SEARCHED_USERS,
  users,
});

export const searchUsers = searchQuery => (dispatch) => {
  const url = `/api/search/users?q=${searchQuery}`;
  const params = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  };
  return dispatch(authFetch(url, params))
    .then(res => dispatch(handleResponse(res, receiveSearchedUsers)));
};

export const searchInviteUsers = (searchQuery, postId) => (dispatch) => {
  const url = `/api/search/uninvited-users?pid=${postId}&q=${searchQuery}`;
  const params = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  };
  return dispatch(authFetch(url, params))
    .then(res => dispatch(handleResponse(res, receiveInviteSearchedUsers)));
};
