// when u click [...] on someone's post
const POST_OPTIONS_MODAL = 'postOptionsModal';
// when post author clicks [...] on their post
const AUTHOR_OPTIONS_MODAL = 'authorOptionsModal';
const DELETE_ACCOUNT_MODAL = 'deleteAccountModal';
const INVITE_MODAL = 'inviteModal';

const ERROR_BAR_MODAL = 'errorBarModal';

// eslint-disable-next-line import/prefer-default-export
export const modalTypes = {
  POST_OPTIONS_MODAL,
  AUTHOR_OPTIONS_MODAL,
  ERROR_BAR_MODAL,
  DELETE_ACCOUNT_MODAL,
  INVITE_MODAL,
};
