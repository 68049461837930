/**
 * VerifyEmailPage is the page a user is taken to when they click an
 * email verification link in their inbox.
 */
import * as React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { useQuery } from '../util';

const VerifyEmail: React.FC<{}> = () => {
  const [statusCode, setStatusCode] = React.useState(-1);
  const query = useQuery();

  React.useEffect(() => {
    axios
      .get(`/api/public/confirm?token=${query.get('token')}`)
      .then((res) => setStatusCode(res.status))
      .catch((e) => setStatusCode(e.response.status));
  }, []);

  return (
    <div>
      <div className="landing-message">
        {statusCode === 200 && (
          <p>
            Verified! please log in again{' '}
            <Link className="underline" to="/login">
              here
            </Link>
            .
          </p>
        )}
        {statusCode > 0 && statusCode !== 200 && (
          <p>
            Verification failed. Resend verification email{' '}
            <Link className="underline" to="/send-verify-email">
              here
            </Link>
            .
          </p>
        )}
        {statusCode === -1 && 'Working...'}
      </div>
    </div>
  );
};

export default VerifyEmail;
