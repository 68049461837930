import { Action } from 'redux';
import * as types from '@happenings/components/constants/actionTypes';
import { FetchAction } from '@happenings/components/common/types';

export enum ActivityType {
  Star = 'STAR',
  Comment = 'COMMENT',
  Attend = 'ATTEND',
  Create = 'CREATE',
}

const INTERACTION_PHRASES: { [key in ActivityType]: string } = {
  [ActivityType.Star]: 'saved',
  [ActivityType.Comment]: 'commented on',
  [ActivityType.Attend]: 'is attending',
  [ActivityType.Create]: 'created',
};

export function conjugate(
  activityType: ActivityType,
  followee: string,
  author: string
): string {
  // filter out things like 'saved by elcal' when it's an elcal post
  if (followee == author) return '';
  const phrase = INTERACTION_PHRASES[activityType];
  if (activityType === ActivityType.Create) {
    return `${phrase} by ${author}`;
  }
  if (activityType === ActivityType.Attend) {
    return `${followee} ${phrase}`;
  }
  return `${phrase} by ${followee}`;
}

// interface for slice of state
export interface Feed {
  postIDs: number[];
}

interface post {
  id: number;
  seqNum: number;
}

export interface FeedAction extends Action {
  feedName: string;
  posts: post[];
}

/**
 * ACTION CREATORS
 */
export const getFeed = (feedName: string): FetchAction => ({
  type: 'GET_FEED',
  payload: { feedName },
});

const pluckIDs = (posts: Array<post> = []): number[] => posts.map((p) => p.id);

const INITIAL_STATE: Feed = {
  postIDs: [],
};

/**
 * feedReducer preserves ordering in paginated feed flows
 */
const feedReducer = (state: Feed = INITIAL_STATE, action: FeedAction): Feed => {
  switch (action.type) {
    case types.RECEIVE_FEED_PAGE:
      return {
        ...state,
        // append to the end of the list
        postIDs: [...state.postIDs, ...pluckIDs(action.posts)],
      };
    case types.ROUTE_CHANGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default feedReducer;
