import React from 'react';

import LogoSvg from '../../assets/newlogo.svg';
import LongArrow from '../../assets/long-arrow.svg';

const LegalPageHeader = ({ title }) => {
  return (
    <div className="legal-page-header">
        <div className="back-button-container">
          <a href="/" className="back-button">
            <img src={LongArrow} alt="Go Back" />
          </a>
        </div>
        <figure>
            <img width="151px" height="252px" loading="eager" className="logo" src={LogoSvg} alt="Happenings Logo" />
        </figure>
        <h1 className="text-body header-title">Happenings</h1>
        <h2 className="page-title helv-condensed-black">{title || 'Terms'}</h2>
    </div>
  );
};

export default LegalPageHeader;
