import * as React from 'react';

import HeaderContainer from './header/HeaderContainer';
import ModalContainer from './modals/ModalContainer';
import ApiErrorHandler from './errors/ApiErrorHandler';
import Main from './Main';
import LoadingIcon from './common/LoadingIcon';

interface Location {
  readonly pathname: string,
}

type AppProps = {
  initSession(): void,
  declareRouteChange(pathname: string): void,
  loggedIn: boolean,
  loaded: boolean,
  hasApiError: boolean,
  location: Location,
}

class App extends React.Component<AppProps, {}> {
  componentDidMount() {
    /**
     * The top level API call!
     * Uses JWT cookie, if exists, to re-authenticate user.
     */
    this.props.initSession();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      // TODO this is embarassing. figure out why inbox components mount and
      // start fetching data before the router registers the route change
      const { pathname } = this.props.location;
      if (!(pathname.includes('inbox') || pathname.includes('event'))) {
        this.props.declareRouteChange(pathname);
      }
    }
  }

  render() {
    const { loaded, loggedIn, hasApiError } = this.props;
    if (hasApiError) {
      return <ApiErrorHandler />;
    }
    return loaded ? (
      <div>
        <HeaderContainer />
        <Main loggedIn={loggedIn} loaded={loaded} />
        <ModalContainer />
      </div>
    ) : <LoadingIcon />;
  }
}

export default App;
