import React, { cloneElement } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const mapStateToProps = state => ({
  error: state.errors.error,
});

const mapDispatchToProps = dispatch => ({
  clearError: () => dispatch({ type: 'CLEAR_SESSION_ERRORS' }),
});

const errWrapper = ({ children, trigger, error, clearError, msg }) => {
  const shouldDisplay = error === trigger;
  const message = msg || error;
  return (
    <div>
      { cloneElement(children, { onChange: shouldDisplay ? clearError : null }) }
      { shouldDisplay && <span className="form-error">{message}</span>}
    </div>
  );
};

errWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  trigger: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  clearError: PropTypes.func.isRequired,
  msg: PropTypes.string,
};

errWrapper.defaultProps = {
  msg: '',
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(errWrapper);
