import React from 'react';
import PropTypes from 'prop-types';

import UpdateButton from '../util/UpdateButton';

class DeleteAccountModal extends React.Component {
  redirectToHomepage = () => this.props.history.push('/');

  handleDelete = () => {
    this.props.deleteUserAccount(this.props.currentUser.id);
    this.props.closeModal();
    this.redirectToHomepage();
    this.props.clearCookies();
  };

  render() {
    return (
      <div>
        <div className="modal-option border-bottom">
          Are you sure you want to delete your account? Your data will be lost
          forever.
        </div>
        <div className="modal-option border-bottom">
          <UpdateButton
            text="[delete account]"
            handlerFunc={this.handleDelete}
          />
        </div>
        <div className="modal-option border-bottom">
          <UpdateButton
            text="[cancel]"
            handlerFunc={this.props.closeModal}
          />
        </div>
      </div>
    );
  }
}

DeleteAccountModal.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number,
    token: PropTypes.string,
    username: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,

  deleteUserAccount: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  clearCookies: PropTypes.func.isRequired,
};

export default DeleteAccountModal;
