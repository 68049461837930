import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { TIMELINE_FEED_CHANGE } from '@happenings/components/constants/actionTypes';
import Store from '@happenings/components/store';
import { postIDsForTimeline, getProfilePosts } from '@happenings/components/profile';
import { filterPosts } from '@happenings/components/post';

import ProfilePosts from './ProfilePosts';

const mapStateToProps = (state: Store, ownProps) => ({
  postsByFeed: {
    ATTENDING: filterPosts(
      state.entities.posts,
      postIDsForTimeline(state, ownProps.username, 'ATTENDING')
    ),
    HOST: filterPosts(
      state.entities.posts,
      postIDsForTimeline(state, ownProps.username, 'HOST')
    ),
    STARRED: filterPosts(
      state.entities.posts,
      postIDsForTimeline(state, ownProps.username, 'STARRED')
    ),
  },
  loading: state.ui.loading.postsLoading,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getTimelinePosts: (feedType) =>
    dispatch(getProfilePosts(ownProps.username, feedType)),
  // treat feed changes as route changes to clear out old feed data
  changeFeed: () => dispatch({ type: TIMELINE_FEED_CHANGE }),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfilePosts)
);
