import { Action } from 'redux';

export type ProfileType = 'PERSON' | 'PLACE' | 'THING';

export type UserListItem = {
  username: string;
  isFollowing?: boolean;
  awaitingApproval?: boolean;
}

export type User = {
  id: number;
  username: string;
  bio: string;
  entityUrl: string;
  isPrivate: boolean;
  userType: ProfileType;
};

export type Follower = {
  follower_id: number;
  username: string;
  awaiting_approval: boolean;
};

export type Following = {
  user_id: number;
  username: string;
  awaiting_approval: boolean;
};

export type EditableUserFields = {
  username?: string;
  email?: string;
  bio?: string;
  entityUrl?: string;
  password?: string;
  confirmPassword?: string;
  isPrivate?: boolean;
  userType?: ProfileType;
};

// user state
export type UserSlice = Record<string, User>;
const DEFAULT_STATE: UserSlice = {};
export type UserAction = Action & { user: User };

export enum actionTypes {
  GET_USER = 'GET_USER',
  RECEIVE_USER = 'RECEIVE_USER',
  UPDATE_USER_INFO = 'UPDATE_USER_INFO',
  DELETE_USER_ACCOUNT = 'DELETE_USER_ACCOUNT',
  BLOCK_USER = 'BLOCK_USER',
}

export const deleteUserAccount = (userId: number) => ({
  type: actionTypes.DELETE_USER_ACCOUNT,
  payload: { userId },
});

export const blockUser = (userId: number) => ({
  type: actionTypes.BLOCK_USER,
  payload: { userId },
});

export const updateUserInfo = (userId: number, formData: EditableUserFields) => ({
  type: actionTypes.UPDATE_USER_INFO,
  payload: { userId, formData },
});

export const fetchUserByUsername = (username: string) => ({
  type: actionTypes.GET_USER,
  payload: { username },
});


const reducer = (state = DEFAULT_STATE, action: UserAction) => {
  switch (action.type) {
    case actionTypes.RECEIVE_USER:
      return { ...state, [action.user.username]: action.user };
    default:
      return state;
  }
};

export default reducer;
