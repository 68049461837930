import * as types from '@happenings/components/constants/actionTypes';
import { actionTypes as userActions } from '@happenings/components/user';

export interface Loading {
  currUserLoading: boolean;
  inboxLoading: boolean;
  notificationsLoading: boolean;
  userLoading: boolean;
  postsLoading: boolean;
  eventUploading: boolean;
  updateInProgress: boolean;
  commentsLoading: boolean;
}

const INITIAL_STATE: Loading = {
  currUserLoading: false, // for session auth
  inboxLoading: false,
  notificationsLoading: false,
  userLoading: false, // for profile page, account settings
  postsLoading: false,
  eventUploading: false,
  updateInProgress: false,
  commentsLoading: false,
};

const loadingReducer = (state: Loading = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.BEGIN_PAGE_FETCH:
      return { ...state, postsLoading: true };
    case types.RECEIVE_FEED_PAGE:
      return { ...state, postsLoading: false };
    case types.RECEIVE_PROFILE_POSTS:
      return { ...state, postsLoading: false };

    case types.BEGIN_CURR_USER_INFO_FETCH:
      return { ...state, currUserLoading: true };
    case types.RECEIVE_CURRENT_USER:
      return { ...state, currUserLoading: false };

    case types.BEGIN_USER_FETCH:
      return { ...state, userLoading: true };
    case userActions.RECEIVE_USER:
      return { ...state, userLoading: false };

    case types.BEGIN_NOTIFICATION_FETCH:
      return { ...state, notificationsLoading: true };
    case types.RECEIVE_NOTIFICATION_PAGE:
      return { ...state, notificationsLoading: false };

    case types.BEGIN_INBOX_FETCH:
      return { ...state, inboxLoading: true };
    case types.RECEIVE_INBOX_EVENTS:
      return { ...state, inboxLoading: false };

    case types.BEGIN_POST_FETCH:
      return { ...state, postsLoading: true };
    case types.RECEIVE_POST_DATA:
      return { ...state, postsLoading: false };

    case types.BEGIN_COMMENT_FETCH:
      return { ...state, commentsLoading: true };
    case types.RECEIVE_COMMENTS:
      return { ...state, commentsLoading: false };

    case types.BEGIN_EVENT_UPLOAD:
      return { ...state, eventUploading: true };
    case types.COMPLETE_EVENT_UPLOAD:
      return { ...state, eventUploading: false };

    case types.BEGIN_SERVER_UPDATE:
      return { ...state, updateInProgress: true };
    case types.SERVER_UPDATE_SUCCESS:
      return { ...state, updateInProgress: false };

    case types.CANCEL_PAGE_FETCH:
      return INITIAL_STATE;
    case types.RECEIVE_SESSION_ERRORS:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default loadingReducer;
