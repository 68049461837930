import * as React from 'react';

const ProgressiveImg = ({ placeholderSrc, src, ...props }) => {
  const [ imgSrc, setSrc ] = React.useState<string>(placeholderSrc);

  React.useEffect(() => {
    const img = new Image();
    img.src = src; // set src to full image
    img.onload = () => setSrc(src);
  }, [ src ]);

  const customClass =
    placeholderSrc && imgSrc === placeholderSrc ? "loading" : "loaded";

  return (
    <img
      {...{ src: imgSrc, ...props }}
      className={`image ${customClass}`}
      ref={props.ref}
    />
  );
};

export default ProgressiveImg;
