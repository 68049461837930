import { ADD_COMMENT, DELETE_COMMENT, CommentActionTypes, CLEAR_COMMENTS, GET_COMMENTS } from './types';

export const addComment = (postId: number, userId: number, text: string): CommentActionTypes => {
  return {
    type: ADD_COMMENT,
    payload: { postId, userId, text },
  }
};

export const deleteComment = (postId: number, commentId: number): CommentActionTypes => {
  return {
    type: DELETE_COMMENT,
    payload: { postId, commentId },
  }
};

export const clearComments = (postId: number): CommentActionTypes => {
  return {
    type: CLEAR_COMMENTS,
    payload: { postId },
  }
};

export const getComments = (postId: number): CommentActionTypes => {
  return {
    type: GET_COMMENTS,
    payload: { postId },
  }
};
