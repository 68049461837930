import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';


const DefaultFallbackComponent = ({ errMsg }) => {
  return (
    <div>{errMsg}</div>
  );
};

DefaultFallbackComponent.propTypes = {
  errMsg: PropTypes.string.isRequired,
};


class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    fallbackComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  }

  static defaultProps = {
    fallbackComponent: DefaultFallbackComponent,
  }

  state = {
    eventId: '',
    errorMessage: '',
  }

  static getDerivedStateFromError(error) {
    return { errorMessage: error.toString() };
  }

  componentDidCatch(error, info) {
    Sentry.withScope((scope) => {
      scope.setExtras(info);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    const FallBackUI = this.props.fallbackComponent;
    if (this.state.errorMessage) {
      return (
        <FallBackUI
          errMsg={this.state.errorMessage}
          errCallback={() => Sentry.showReportDialog({ eventId: this.state.eventId })}
        />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
