import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';

import classNames from 'classnames';

import { currUserAttendanceStatus, updateAttendance, AttendanceType } from '@happenings/components/entities/attendance';
import Store from '@happenings/components/store';

type ownProps = {
  postId: number;
  starCount: number;
  attendingCount: number;
  isStarred: boolean;
  commentHandler: () => void;
  updateStar: (currVal: boolean) => void;
};

const mapState = (state: Store, ownProps: ownProps) => ({
  currUserAttendanceStatus: currUserAttendanceStatus(state, ownProps.postId),
});

const mapDispatch = {
  updateAttendance: (postId: number, newVal: AttendanceType) => updateAttendance(postId, newVal),
};

const connector = connect(mapState, mapDispatch);
type injectedProps = ConnectedProps<typeof connector>;
type Props = ownProps & injectedProps;

const PostStats: React.FC<Props> = ({
  postId,
  starCount,
  attendingCount,
  isStarred,
  commentHandler,
  currUserAttendanceStatus,
  updateStar,
  updateAttendance,
}) => {
  const startBtnText = isStarred ? 'Saved' : 'Save';

  const starBtnClass = classNames('post-btn item', { 'active': isStarred });

  const clickHandler = (currVal: AttendanceType, newVal: AttendanceType) => {
    if (currVal !== newVal) {
      updateAttendance(postId, newVal);
    }
  };
  return (
    <div>
      <div className="post-stats-container">
        <div className="stat-container">
          <div role="button" className="left-btn item" onClick={commentHandler}>Comment</div>
        </div>
        <div className="stat-container">
          <button
            className={starBtnClass}
            type="button"
            // toggles the current val of isStarred
            onClick={() => updateStar(isStarred)}
          >
            {startBtnText}
          </button>
          <button
            className={(classNames('post-btn attend', { 'active': currUserAttendanceStatus === AttendanceType.GOING }))}
            type="button"
            onClick={() => clickHandler(currUserAttendanceStatus, AttendanceType.GOING)}
          >
            Going
          </button>

          <button
            className={(classNames('post-btn attend', { 'active': currUserAttendanceStatus === AttendanceType.MAYBE }))}
            type="button"
            onClick={() => clickHandler(currUserAttendanceStatus, AttendanceType.MAYBE)}
          >
            Maybe
          </button>

          <button
            className={(classNames('post-btn attend', { 'active': currUserAttendanceStatus === AttendanceType.NOT_GOING }))}
            type="button"
            onClick={() => clickHandler(currUserAttendanceStatus, AttendanceType.NOT_GOING)}
          >
            Not Going
          </button>
        </div>
      </div>
      <Link to={`/event/${postId}/attendance`} className="item">
        {`${attendingCount} attending, ${starCount} saved`}
      </Link>
    </div>
  );
};

export default connect(
  mapState,
  mapDispatch,
)(PostStats);
