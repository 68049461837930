import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { modalTypes } from '../../constants/modalTypes';
import PostOptionsModalContainer from './PostOptionsModalContainer';
import AuthorOptionsModalContainer from './AuthorOptionsModalContainer';
import DeleteAccountModalContainer from './DeleteAccountModalContainer';
import InviteModalContainer from './InviteModalContainer';
import ErrorBarModal from './ErrorBarModal';

const NO_MODAL_OPEN = '__NO_MODAL_OPEN'; // default prop for modalName if undefined

const Modal = ({ modalName, closeModal }) => {
  const getModal = (name) => {
    // eslint-disable-next-line default-case
    switch (name) {
      case modalTypes.POST_OPTIONS_MODAL:
        return <PostOptionsModalContainer />;
      case modalTypes.AUTHOR_OPTIONS_MODAL:
        return <AuthorOptionsModalContainer />;
      case modalTypes.ERROR_BAR_MODAL:
        return <ErrorBarModal />;
      case modalTypes.DELETE_ACCOUNT_MODAL:
        return <DeleteAccountModalContainer />;
      case modalTypes.INVITE_MODAL:
        return <InviteModalContainer />;
      // do not put a default, it will always get reset to this
    }
  };
  const isOpen = modalName !== NO_MODAL_OPEN;
  const modalClass = classNames('modal', { 'is-open': isOpen });
  const modal = getModal(modalName);
  return (
    <div className={modalClass}>
      <div className="modal-box">
        <span role="button" onClick={() => closeModal(modalName)}>[x]</span>
        {modal}
      </div>
    </div>
  );
};

Modal.propTypes = {
  modalName: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
};

Modal.defaultProps = {
  modalName: NO_MODAL_OPEN,
};

export default Modal;
