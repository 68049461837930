import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchUserByUsername, updateUserInfo } from '@happenings/components/user';
import { logoutUser } from '@happenings/components/session';
import { openModal, closeModal } from '../../actions/ui_actions';
import { modalTypes as MODAL_TYPES } from '../../constants/modalTypes';

import AccountSettings from './AccountSettings';

const mapStateToProps = state => ({
  currentUser: state.session.currentUser,
  userInfo: state.entities.users[state.session.currentUser.username],
  loaded:
    !state.ui.loading.userLoading
    && !!state.entities.users[state.session.currentUser.username],
  deleteAccountModalOpen: !!state.ui.modals.deleteAccountModal,
});

const mapDispatchToProps = dispatch => ({
  logOut: () => dispatch(logoutUser()),
  fetchUserByUsername: username => dispatch(fetchUserByUsername(username)),
  updateUserInfo: (userId, userInfo) => dispatch(updateUserInfo(userId, userInfo)),
  openDeleteAccountModal: () => dispatch(openModal(MODAL_TYPES.DELETE_ACCOUNT_MODAL, true)),
  closeDeleteAccountModal: () => dispatch(closeModal(MODAL_TYPES.DELETE_ACCOUNT_MODAL)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AccountSettings),
);
