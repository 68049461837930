import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const SubPageHeader = ({ prevRoute = '/' }) => {
  return (
    <div className="header-container">
      <header className="header-content">
        <Link to={prevRoute}>
          <h1 className="logo">{'< Happenings'}</h1>
        </Link>
      </header>
    </div>
  );
};

SubPageHeader.propTypes = {
  prevRoute: PropTypes.string,
};

SubPageHeader.defaultProps = {
  prevRoute: '/',
};

export default SubPageHeader;
