import { connect } from 'react-redux';

import SearchUsers from './SearchUsers';
import { searchInviteUsers } from '../../actions/user_actions';

const mapStateToProps = state => ({
  searchedEntity: state.ui.search.searchedEntity,
  barOpen: state.ui.search.barOpen,
});

const mapDispatchToProps = dispatch => ({
  searchUsers: (query, postId) => dispatch(searchInviteUsers(query, postId)),
  // to-do: action to clear invite users search
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchUsers);
