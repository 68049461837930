import axios from 'axios';
import {
  put,
  takeEvery,
  take,
  call,
  select,
  cancelled,
  race,
  takeLatest,
} from 'redux-saga/effects';
import {
  GET_COMMENTS,
  ADD_COMMENT,
  DELETE_COMMENT,
  RECEIVE_COMMENTS,
} from '@happenings/components/entities/comments/types';

import * as types from '@happenings/components/constants/actionTypes';
import { ErrorCode } from '@happenings/components/errors';
import { getFetchParams } from '@happenings/components/util';
import { getToken, getCurrUser } from '@happenings/components/session';
import Store from '@happenings/components/store';
import { PageState, DEFAULT_STATE } from '@happenings/components/pagination';
import { ApiPayload } from '../common/types';

type postPayload = ApiPayload & { postId: number; userId?: number };
type commentPayload = postPayload & { text?: string; commentId?: string };

export const getPagination = (state: Store, postId: number): PageState =>
  state.entities.comments[postId]?.pagination || DEFAULT_STATE;

export const isAuthedSession = (state: Store) => !!state.session.currentUser;

export function* getComments({
  payload,
}: {
  type: string;
  payload: commentPayload;
}) {
  try {
    const { lastEvaluatedKey, hasMore } = yield select(
      getPagination,
      payload.postId
    );
    if (hasMore) {
      const token = yield select(getToken);
      const pageParam = lastEvaluatedKey ? `startAfter=${lastEvaluatedKey}` : '';
      const url = `${payload.url}/api/posts/${payload.postId}/comments?${pageParam}`;
      const params = getFetchParams(token);

      yield put({ type: types.BEGIN_COMMENT_FETCH });
      const res = yield call(axios.get, url, params);
      yield put({
        type: RECEIVE_COMMENTS,
        payload: { postId: payload.postId, comments: res.data },
      });
    }
  } catch (e) {
    yield put({
      type: types.RECEIVE_SESSION_ERRORS,
      statusCode: e?.response?.status || 400,
    });
  }
}

export function* addComment({
  payload,
}: {
  type: string;
  payload: commentPayload;
}) {
  try {
    const { text, userId } = payload;
    const token = yield select(getToken);
    const url = `${payload.url}/api/posts/${payload.postId}/comments`;
    yield put({ type: types.BEGIN_SERVER_UPDATE });
    yield call(axios.post, url, { text, userId }, getFetchParams(token));
    yield put({ type: types.SERVER_UPDATE_SUCCESS });
    // re-fetch ater server update. TODO optimistic UI update
    yield put({ type: GET_COMMENTS, payload });
  } catch (e) {
    yield put({ type: types.RECEIVE_SESSION_ERRORS });
  }
}

export function* deleteComment({
  payload,
}: {
  type: string;
  payload: commentPayload;
}) {
  try {
    const { commentId } = payload;
    const token = yield select(getToken);
    const url = `${payload.url}/api/posts/${payload.postId}/comments?comment_id=${commentId}`;
    yield put({ type: types.BEGIN_SERVER_UPDATE });
    yield call(axios.delete, url, getFetchParams(token));
    yield put({ type: types.SERVER_UPDATE_SUCCESS });
  } catch (e) {
    yield put({ type: types.RECEIVE_SESSION_ERRORS });
  }
}

export function* getAttendance({
  payload,
}: {
  type: string;
  payload: postPayload;
}) {
  try {
    const token = yield select(getToken);
    const { postId } = payload;
    const url = `${payload.url}/api/attendance/post/${postId}`;
    const params = getFetchParams(token);
    const res = yield call(axios.get, url, params);
    yield put({
      type: types.RECEIVE_POST_ATTENDNACE,
      postId,
      attendanceList: res.data,
    });
  } catch (e) {
    yield put({ type: types.RECEIVE_SESSION_ERRORS, statusCode: 400 });
  }
}

export function* createAttendance({
  payload,
}: {
  type: string;
  payload: postPayload & { attendanceStatus: string };
}) {
  try {
    const token = yield select(getToken);
    const user = yield select(getCurrUser);
    const url = `${payload.url}/api/attendance/post/${payload.postId}`;
    const params = getFetchParams(token);
    const body = { userId: user.id, attendanceStatus: payload.attendanceStatus };
    yield put({ type: types.BEGIN_SERVER_UPDATE });
    yield call(axios.post, url, body, params);
    yield put({ type: types.SERVER_UPDATE_SUCCESS });
    // re-fetch after server update
    yield put({ type: 'GET_ATTENDANCE', payload });
  } catch (e) {
    yield put({ type: types.RECEIVE_SESSION_ERRORS, statusCode: 500 });
  }
}

export function* removeAttendance({
  payload,
}: {
  type: string;
  payload: postPayload;
}) {
  try {
    const { userId, postId } = payload;
    const token = yield select(getToken);
    // TODO: why does this endpoint use query params..
    const url = `${payload.url}/api/attendance/?post_id=${postId}&user_id=${userId}`;
    const params = getFetchParams(token);
    yield put({ type: types.BEGIN_SERVER_UPDATE });
    yield call(axios.delete, url, params);
    yield put({ type: types.SERVER_UPDATE_SUCCESS });
    // re-fetch after server update
    yield put({ type: 'GET_ATTENDANCE', payload });
  } catch (e) {
    yield put({ type: types.RECEIVE_SESSION_ERRORS, statusCode: 400 });
  }
}

export function* getSaves({ payload }: { type: string; payload: postPayload }) {
  try {
    const token = yield select(getToken);
    const url = `${payload.url}/api/posts/${payload.postId}/stars`;
    const params = getFetchParams(token);
    const res = yield call(axios.get, url, params);
    yield put({
      type: types.RECEIVE_POST_STARS,
      postId: payload.postId,
      stars: res.data,
    });
  } catch (e) {
    yield put({ type: types.RECEIVE_SESSION_ERRORS, statusCode: 400 });
  }
}

export function* createSave({
  payload,
}: {
  type: string;
  payload: postPayload;
}) {
  try {
    const token = yield select(getToken);
    const url = `${payload.url}/api/posts/${payload.postId}/stars`;
    const params = getFetchParams(token);
    const body = { userId: payload.userId };
    yield put({ type: types.BEGIN_SERVER_UPDATE });
    yield call(axios.post, url, body, params);
    yield put({ type: types.SERVER_UPDATE_SUCCESS });
    // re-fetch data after server udpate
    yield put({ type: 'GET_SAVES', payload });
  } catch (e) {
    yield put({ type: types.RECEIVE_SESSION_ERRORS, statusCode: 500 });
  }
}

export function* removeSave({
  payload,
}: {
  type: string;
  payload: postPayload;
}) {
  try {
    const token = yield select(getToken);
    const url = `${payload.url}/api/posts/${payload.postId}/stars`;
    const params = getFetchParams(token);
    yield put({ type: types.BEGIN_SERVER_UPDATE });
    yield call(axios.delete, url, params);
    yield put({ type: types.SERVER_UPDATE_SUCCESS });
    // re-fetch data after server udpate
    yield put({ type: 'GET_SAVES', payload });
  } catch (e) {
    yield put({ type: types.RECEIVE_SESSION_ERRORS, statusCode: 500 });
  }
}

export function* getPostData({
  payload,
}: {
  type: string;
  payload: postPayload;
}) {
  try {
    const maybeToken = yield select(getToken);
    const isAuthed = yield select(isAuthedSession);

    const url = isAuthed
      ? `${payload.url}/api/posts/${payload.postId}`
      : `${payload.url}/api/public/posts/${payload.postId}`;

    const params = getFetchParams(maybeToken);
    yield put({ type: types.BEGIN_PAGE_FETCH });
    const res = yield call(axios.get, url, params);
    const post = res.data;
    yield put({ type: types.RECEIVE_POST_DATA, post });
  } catch (e) {
    const { error } = e.response.data;
    if (e.response && e.response.status >= 400) {
      yield put({
        type: types.RECEIVE_SESSION_ERRORS,
        error,
        statusCode: e.response.status,
      });
      yield put({
        type: 'SET_ERROR_CODE',
        errorCode: ErrorCode.EVENT_NOT_FOUND,
      })
    } else {
      yield put({ type: types.RECEIVE_SESSION_ERRORS, error, statusCode: 500 });
    }
  } finally {
    if (yield cancelled()) {
      // reset loading states
      yield put({ type: types.CANCEL_PAGE_FETCH });
    }
  }
}

export function* deletePost({
  payload,
}: {
  type: string;
  payload: postPayload;
}) {
  try {
    const url = `${payload.url}/api/posts/${payload.postId}`;
    const token = yield select(getToken);
    const params = getFetchParams(token);
    yield put({ type: types.BEGIN_SERVER_UPDATE });
    yield call(axios.delete, url, params);
    yield put({ type: types.SERVER_UPDATE_SUCCESS });
  } catch (e) {
    yield put({ type: types.RECEIVE_SESSION_ERRORS, statusCode: 500 });
  }
}

export default function* postSaga() {
  yield takeLatest(
    GET_COMMENTS,
    function* (args: { type: string; payload: commentPayload }) {
      yield race({
        task: call(getComments, args),
        cancel: take(types.ROUTE_CHANGE),
      });
    }
  );
  yield takeLatest('DELETE_POST', deletePost);
  yield takeEvery(ADD_COMMENT, addComment);
  yield takeEvery(DELETE_COMMENT, deleteComment);

  yield takeEvery('GET_ATTENDANCE', getAttendance);
  yield takeEvery('CREATE_ATTENDANCE', createAttendance);
  yield takeEvery('REMOVE_ATTENDANCE', removeAttendance);

  yield takeEvery('GET_SAVES', getSaves);
  yield takeEvery('CREATE_SAVE', createSave);
  yield takeEvery('REMOVE_SAVE', removeSave);

  yield takeEvery(
    'GET_POST_DATA',
    // if route changes (web) before getPostData completes, cancel the generator
    function* (action: { type: string; payload: postPayload }) {
      yield race({
        task: call(getPostData, action),
        cancel: take(types.ROUTE_CHANGE),
      });
    }
  );
}
