/* eslint-disable func-names */
import axios from 'axios';
import { put, takeLatest, takeEvery, call, select, cancelled } from 'redux-saga/effects';

import { TIMELINE_FEED_TYPES } from '@happenings/components/profile/constants';
import * as types from '@happenings/components/constants/actionTypes';
import { User } from '@happenings/components/user';
import { getToken } from '@happenings/components/session';
import { getFetchParams } from '@happenings/components/util';
import { ApiAction, ApiPayload } from '../common/types';
import Store from '@happenings/components/store';
import { PageState } from '@happenings/components/pagination';
import { TimelineType } from '@happenings/components/profile';
import {
  actionTypes as userActions,
  fetchUserByUsername,
} from '@happenings/components/user';

export const FEED_TYPE_TO_URI_COMPONENT = {
  [TIMELINE_FEED_TYPES.HOST]: 'hosting',
  [TIMELINE_FEED_TYPES.STARRED]: 'starred',
  [TIMELINE_FEED_TYPES.ATTENDING]: 'attending',
};

interface getProfileAction extends ApiAction {
  payload: ApiPayload & { username: string };
}

export function* getUserByUsername({ payload }: getProfileAction) {
  try {
    const url = `${payload.url}/api/users/${payload.username}`;
    const token = yield select(getToken);
    const params = getFetchParams(token);
    yield put({ type: types.BEGIN_USER_FETCH });
    const res = yield call(axios.get, url, params);
    yield put({ type: userActions.RECEIVE_USER, user: res.data });
  } catch (e) {
    const res = e.response;
    yield put({
      type: types.RECEIVE_SESSION_ERRORS,
      statusCode: res?.status || 404,
    });
  }
}

interface getProfilePostsAction extends ApiAction {
  payload: ApiPayload & { username: string; feedType: TimelineType };
}

export const getPagination = (
  state: Store,
  username: string,
  feedType: TimelineType
): PageState => {
  if (!state.pagination.profile[username]) {
    return { lastEvaluatedKey: null, hasMore: true, keys: [] };
  }
  if (!state.pagination.profile[username][feedType]) {
    return { lastEvaluatedKey: null, hasMore: true, keys: [] };
  }
  return state.pagination.profile[username][feedType];
}

export function* getProfilePosts({ payload }: getProfilePostsAction) {
  try {
    const pagination = yield select(getPagination, payload.username, payload.feedType);
    const feedURIComponent = FEED_TYPE_TO_URI_COMPONENT[payload.feedType];
    const { lastEvaluatedKey, hasMore } = pagination;
    if (hasMore) {
      const token = yield select(getToken);
      const pageParam = lastEvaluatedKey
        ? `?startAfter=${lastEvaluatedKey}`
        : '';
      const url = `${payload.url}/api/timeline/${payload.username}/${feedURIComponent}${pageParam}`;
      const params = getFetchParams(token);
      yield put({ type: types.BEGIN_PAGE_FETCH });
      const res = yield call(axios.get, url, params);

      yield put({
        type: types.RECEIVE_PROFILE_POSTS,
        posts: res.data,
        feedType: payload.feedType,
        username: payload.username,
      });
    }
  } catch (e) {
    yield put({ type: types.RECEIVE_SESSION_ERRORS, error: e.response });
  } finally {
    if (yield cancelled()) {
      yield put({ type: types.CANCEL_PAGE_FETCH });
    }
  }
}

type ProfileForm = Omit<User, "id"> & { password: string };

interface userAction extends ApiAction {
  payload: ApiPayload & { userId: number };
}

interface updateProfileAction extends userAction {
  payload: ApiPayload & { userId: number; formData: ProfileForm };
}

export function* updateUserInfo({ payload }: updateProfileAction) {
  try {
    const token = yield select(getToken);
    const url = `${payload.url}/api/users/${payload.userId}`;
    const params = getFetchParams(token);
    yield put({ type: types.CLEAR_SESSION_ERRORS });
    yield put({ type: types.BEGIN_SERVER_UPDATE });
    yield call(axios.put, url, payload.formData, params);
    // re-hydrate user data from the API after udpate
    yield put(fetchUserByUsername(payload.formData.username));
    yield put({ type: types.SERVER_UPDATE_SUCCESS });
  } catch (e) {
    const { error } = e.response?.data;
    if (e.response && e.response.status === 400) {
      yield put({ type: types.RECEIVE_SESSION_ERRORS, error });
    } else {
      yield put({ type: types.RECEIVE_SESSION_ERRORS, error, statusCode: 500 });
    }
  }
}

export function* deleteUserAccount({ payload }: updateProfileAction) {
  try {
    const token = yield select(getToken);
    const url = `${payload.url}/api/users/${payload.userId}`;
    const params = getFetchParams(token);
    yield put({ type: types.BEGIN_SERVER_UPDATE });
    yield call(axios.delete, url, params);
    yield put({ type: types.SERVER_UPDATE_SUCCESS });
  } catch (e) {
    yield put({
      type: types.RECEIVE_SESSION_ERRORS,
      error: e.response,
      statusCode: 500,
    });
  }
}

export function* blockUser({ payload }: userAction) {
  try {
    const token = yield select(getToken);
    const params = getFetchParams(token);
    const url = `${payload.url}/api/users/${payload.userId}/block`;
    yield call(axios.post, url, params);
  } catch (e) {
    console.log(e);
  }
}

export default function* main() {
  yield takeLatest(userActions.GET_USER, getUserByUsername);
  yield takeLatest(userActions.UPDATE_USER_INFO, updateUserInfo);
  yield takeLatest(userActions.DELETE_USER_ACCOUNT, deleteUserAccount);
  yield takeLatest(userActions.BLOCK_USER, blockUser);
  yield takeEvery('GET_PROFILE_POSTS', getProfilePosts);
}
