import React from 'react';
import PropTypes from 'prop-types';

import ScrollLoader from '../common/ScrollLoader';
import PostFeed from './PostFeed';
import LoadingIcon from '../common/LoadingIcon';

const MainFeed = ({ postIDs, loading, getMainFeed }) => {
  return (
    <div className="main-feed-container">
      <PostFeed
        postIDs={postIDs}
      />
      <ScrollLoader onEnter={getMainFeed} isLoading={loading} />

      { loading && <LoadingIcon /> }
      { postIDs.length === 0
        && !loading
        && 'no events here yet! go follow some ppl!'
      }
    </div>
  );
};

MainFeed.propTypes = {
  postIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
  loading: PropTypes.bool.isRequired,
  getMainFeed: PropTypes.func.isRequired,
};

export default MainFeed;
