import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { initSession } from '@happenings/components/session';

import { isPublicRoute } from '../utils/auth';
import { ROUTE_CHANGE } from '../constants/actionTypes';

import App from './App.tsx';

/**
 *  state.session.proceedWithoutLogin is set if the auth cookie is not set or expired.
 *  It is also set upon user sign out.
 *
 *  It allows the app to render the public landing page without waiting for userinfo
 *  to finish loading, as it typially does on any page refresh.
 *
 *  It also enables the rendering of public post / profile pages, without a signed in user.
 */
const mapStateToProps = state => ({
  loggedIn: !!state.session.currentUser,
  proceedWithoutLogin: state.session.proceedWithoutLogin,
  hasApiError: !!state.errors.statusCode,
  loaded: (
    state.session.proceedWithoutLogin || (
      !state.ui.loading.currUserLoading && !!state.session.currentUser
    )
  ),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  initSession: () => dispatch(
    initSession(!isPublicRoute(ownProps.location.pathname)),
  ),
  declareRouteChange: newRoute => dispatch({ type: ROUTE_CHANGE, newRoute }),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(App));
