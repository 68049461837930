import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DayPicker from 'react-day-picker';
import classNames from 'classnames';
import moment from 'moment';


const DatePicker = ({ date, onDateChange }) => {
  const [pickerOpen, setPickerOpen] = useState(false);
  const handleDayClick = (d) => {
    onDateChange(d);
    setPickerOpen(false);
  };
  const defaultDate = date || moment().toDate();
  const picker = (
    <div className="date-picker">
      <DayPicker
        onDayClick={handleDayClick}
        initialMonth={defaultDate}
        selectedDays={date}
        firstDayOfWeek={1} // start week on monday
      />
    </div>
  );

  return (
    <div>
      <div
        role="button"
        className={classNames('date-field', { 'placeholder': !date })}
        onClick={() => setPickerOpen(!pickerOpen)}
      >
        {date ? moment(date).format('MM/DD/YYYY') : 'Date'}
      </div>
      {pickerOpen && picker}
    </div>
  );
};

DatePicker.propTypes = {
  date: PropTypes.instanceOf(Date),
  onDateChange: PropTypes.func.isRequired,
};

DatePicker.defaultProps = {
  date: null,
};

export default DatePicker;
