import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getNotifications, countUnreadNotifications } from '@happenings/components/inbox';

import Inbox from './Inbox';

const mapStateToProps = state => ({
  loggedIn: !!state.session.currentUser,
  currentUser: state.session.currentUser,
  notificationIDs: state.pagination.inbox.keys,
  notificationsById: state.entities.notifications,
  loaded: state.ui.loading.notificationsLoading === false,
  unreadNotificationCount: countUnreadNotifications(state),
});

const mapDispatchToProps = dispatch => ({
  getNotifications: () => dispatch(getNotifications()),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Inbox));
