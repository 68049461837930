import React from 'react';
import PropTypes from 'prop-types';

import CheckBoxField from './CheckBoxField';
import AgeRestrictionField from './AgeRestrictionField';

const EventCheckboxForm = ({ formData, handleChange }) => {
  const labelMap = {
    isPrivate: 'Private',
    guestsCanInvite: 'Guests can invite guests',
    isAccessible: 'This event is wheelchair accessible',
  };

  const generateCheckboxes = () => { // eslint-disable-line arrow-body-style
    return Object.keys(labelMap).map(field => (
      <CheckBoxField
        key={field}
        field={field}
        isSelected={formData[field]}
        label={labelMap[field]}
        handleChange={handleChange}
      />
    ));
  };

  return (
    <div>
      { generateCheckboxes() }
      <AgeRestrictionField
        ageRestriction={formData.ageRestriction}
        handleChange={handleChange}
      />
    </div>
  );
};

EventCheckboxForm.propTypes = {
  formData: PropTypes.shape({
    isPrivate: PropTypes.bool,
    guestsCanInvite: PropTypes.bool,
    isAccessible: PropTypes.bool,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default EventCheckboxForm;
