import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import NavButtons from './NavButtons';

const NavHeader = ({ loggedIn, currentUser, unreadNotificationCount }) => {
  const notificationCountFmt = unreadNotificationCount > 9 ? '9+' : unreadNotificationCount;
  return (
    <div className="header-container">
      <header className="header-content">
        <Link to="/">
          <h1 className="logo">Happenings</h1>
        </Link>
        {
          loggedIn
          && <Link to="/inbox" className="notification-count">{notificationCountFmt}</Link>
        }
      </header>

      { loggedIn && <NavButtons currentUser={currentUser} /> }
    </div>
  );
};

NavHeader.propTypes = {
  currentUser: PropTypes.shape({
    username: PropTypes.string,
    id: PropTypes.number,
  }),
  loggedIn: PropTypes.bool.isRequired,
  unreadNotificationCount: PropTypes.number,
};

NavHeader.defaultProps = {
  currentUser: undefined,
  unreadNotificationCount: 0,
};

export default NavHeader;
