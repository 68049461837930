import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { addComment } from '@happenings/components/entities/comments/actions';

import Comment from './Comment';
import AddCommentInput from './AddCommentInput';
import LoadingIcon from '../common/LoadingIcon';

const NUM_COMMENTS_BELOW_POST = 5;

const mapStateToProps = (state, props) => ({
  currentUser: state.session.currentUser,
  comments: state.entities.comments[props.postId]?.comments || [],
  loading: state.ui.loading.commentsLoading,
  // commentPagination: {},
});

const mapDispatchToProps = (dispatch) => ({
  addPostComment: (postId, userId, text) =>
    dispatch(addComment(postId, userId, text)),
});

const PostComments = (props) => {
  const commentHelper = (text) => {
    props.addPostComment(props.postId, props.currentUser.id, text);
    props.submitCallback(props.comments.length, NUM_COMMENTS_BELOW_POST);
  };

  const showComment = (comment) => {
    return (
      <Comment
        key={comment.id}
        id={comment.id}
        username={comment.username}
        text={comment.text}
        userIsAuthor={
          !props.currentUser || comment.username === props.currentUser.username
        }
      />
    );
  };

  const addComments = props.comments
    .slice(0, NUM_COMMENTS_BELOW_POST)
    .map(showComment);

  return (
    <div className="comments-container">
      {addComments}
      {props.loading && <LoadingIcon />}
      {props.comments.length > 0 ? (
        <Link to={`/event/${props.postId}/comments`}>...</Link>
      ) : null}
      {props.currentUser ? (
        <AddCommentInput
          showInputField={props.showInputField}
          addComment={commentHelper}
        />
      ) : null}
    </div>
  );
};

PostComments.propTypes = {
  postId: PropTypes.number.isRequired,
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.text,
      username: PropTypes.text,
      text: PropTypes.text,
    })
  ).isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
  }),
  addPostComment: PropTypes.func.isRequired,
  showInputField: PropTypes.bool.isRequired,
  submitCallback: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

PostComments.defaultProps = {
  currentUser: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(PostComments);
