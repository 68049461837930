import axios from 'axios';
import { put, takeLatest, select, call } from 'redux-saga/effects';

import Store from '@happenings/components/store';
import { getFetchParams } from '@happenings/components/util';
import { getToken } from '@happenings/components/session';
import { ApiPayload } from '@happenings/components/common/types';

import { actionTypes, SEARCHKEY_THRESHOLD } from './index';

interface searchPayload {
  query: string;
}

export const selectSearchKey = (store: Store) => store.ui.search.searchKey;

export function* searchUsers({ payload }: { type: string, payload: ApiPayload & searchPayload }) {
  try {
    if (payload.query.length >= SEARCHKEY_THRESHOLD) {
      const url = `${payload.url}/api/search/users?q=${payload.query}`;
      const token = yield select(getToken);
      const res = yield call(axios.get, url, getFetchParams(token));
      yield put({ type: actionTypes.RECEIVE_SEARCHED_USERS, searchKey: payload.query, results: res.data });
    }
  }
  catch (e) {
    yield put({ type: 'RECEIVE_SESSION_ERRORS' });
  }
};

export function* searchPosts({ payload }: { type: string, payload: ApiPayload & searchPayload }) {
  try {
    if (payload.query.length >= SEARCHKEY_THRESHOLD) {
      const url = `${payload.url}/api/search/posts?q=${payload.query}`;
      const token = yield select(getToken);
      const res = yield call(axios.get, url, getFetchParams(token));
      yield put({ type: actionTypes.RECEIVE_SEARCHED_POSTS, searchKey: payload.query, results: res.data });
    }
  }
  catch (e) {
    yield put({ type: 'RECEIVE_SESSION_ERRORS' });
  }
};

export default function* main() {
  yield takeLatest(actionTypes.SEARCH_USERS, searchUsers);
  yield takeLatest(actionTypes.SEARCH_POSTS, searchPosts);
}
