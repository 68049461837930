import * as React from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import errorsReducer from '@happenings/components/errors';
import { getFetchParams } from '@happenings/components/util';

import UpdateButton from '../util/UpdateButton';
import { EMAIL_FMT_PATTERN } from '../../constants';

const VerifyEmailForm: React.FC<{}> = () => {
  type formFields = { email: string };
  const { register, handleSubmit, errors, formState } = useForm<formFields>({
    mode: 'onSubmit',
  });
  const { isValid } = formState;

  const [submitted, setSubmitted] = React.useState(false);

  const onSubmit = (data: formFields) => {
    axios.post(
      `/api/public/send-verification-email`,
      { email: data.email },
      getFetchParams(),
    );
    // optimistic UI update
    setSubmitted(true);
  };
  console.log(isValid);

  if (submitted) {
    return (
      <div>
        Thanks! If an account with this email exists, you'll receive a message
        in your inbox.
      </div>
    );
  }

  return (
    <div className="form">
      <form>
        <input
          type="text"
          name="email"
          placeholder="email"
          ref={register({
            required: 'email required',
            pattern: {
              value: EMAIL_FMT_PATTERN,
              message: 'invalid email',
            },
          })}
        />
        {errors.email && (
          <span className="form-error">{errors.email.message}</span>
        )}
        <UpdateButton
          text="Send Verification Email"
          handlerFunc={handleSubmit(onSubmit)}
        />
      </form>
    </div>
  );
};

export default VerifyEmailForm;
