import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import SearchResultDropdownContainer from '../menus/SearchDropDownContainer';

const Search = (props) => {
  const [query, setQuery] = useState('');

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          props.clearSearch();
          props.closeSearchBar();
          setQuery('');
        }
      }

      // Bind an event listener to document if bar open; always unbind on clean up
      if (props.barOpen) {
        document.addEventListener('mousedown', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref, props.barOpen]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const executeQuery = () => {
    if (query.length > 0) {
      props.searchPosts(query);
      props.searchUsers(query);
    }
  };

  const handleEsc = (e) => {
    if (e.key === 'Escape') {
      props.clearSearch();
      setQuery('');
    }
  };

  const handleChange = (e) => {
    setQuery(e.currentTarget.value);
    executeQuery();
  };

  const containerClass = classNames('search-container', { folded: !props.barOpen });

  return (
    <div className={containerClass} ref={wrapperRef}>
      <div className="search-field-container">
        <input
          className="search-field"
          type="text"
          placeholder="Search"
          onChange={handleChange}
          onKeyDown={handleEsc}
          onClick={e => e.stopPropagation()}
          value={query}
        />
        <div className="search-offset-box" />
      </div>
      <SearchResultDropdownContainer searchQuery={query} />
    </div>
  );
};

export default Search;
