import * as React from 'react';
import { useForm } from 'react-hook-form';
import { connect, ConnectedProps } from 'react-redux';
import * as Error from '@happenings/components/errors';
import { initSession } from '@happenings/components/session';

import axios from 'axios';
import { getFetchParams } from '@happenings/components/util';
import { validatePhoneNumber, normalizePhoneNumber } from '@happenings/components/util/phone';
import Store from '@happenings/components/store';

import { useQuery } from '../util';
import { useHistory } from 'react-router-dom';

import { UpdateButtonBase as UpdateButton } from '../util/UpdateButton';

const mapState = (state: Store) => ({
  loggedIn: !!state.session.currentUser,
  loading: state.ui.loading.updateInProgress
});

const mapDispatch = {
  init: () => initSession(),
};

const connector = connect(mapState, mapDispatch);
type SignUpProps = ConnectedProps<typeof connector>;

type SignupFormData = {
  phone: string;
};

type SubmitVerificationCodeProps = {
  phone: string;
  onAuthSuccess: (username: string) => void;
  onSuccess: (token: string) => void;
};

const SubmitVerificationCode: React.FC<SubmitVerificationCodeProps> = ({ phone, onSuccess, onAuthSuccess }) => {
  type VerifyForm = {
    code: string;
  };

  const [verificationError, setVerificationError] = React.useState(false);
  const [updateInProgress, setUpdateInProgress] = React.useState(false);

  const { register, handleSubmit, errors } = useForm<VerifyForm>();
  const onSubmit = (data: VerifyForm) => {
    setUpdateInProgress(true);
    const { code } = data;
    axios
      .post('/api/sms/verify-phone-number', { phone, code }, getFetchParams())
      .then((res) => {
        if (res.data.username) {
          console.log('existing account verified, redirecting to homepage or event page');
          onAuthSuccess(res.data.username);
        } else {
          // otherwise, pass the verificationToken onto the next step of the flow
          // this feels very saga-y...
          onSuccess(res.data.verificationToken);
        }
      })
      .catch((e) => {
        console.error(e);
        setVerificationError(true);
      })
      .finally(() => setUpdateInProgress(false));
  };

  return (
    <div className="signup-form">
      <div>Enter the 6 digit verification code we sent you below (may take a minute)</div>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <input
          type="text"
          autoComplete="one-time-code"
          placeholder="XXXXXX"
          inputMode='numeric'
          maxLength={6}
          name="code"
          ref={register({
            required: true,
            pattern: {
              value: /^[0-9]{6}$/,
              message: 'code must be 6 digits'
            }
          })} />
        {errors.code && <span className="form-error">{errors.code.message}</span>}
        {verificationError && <span className="form-error">{'hmm didn\'t work, try reloading the page and sending a new code'}</span>}
        <UpdateButton text="Verify" handlerFunc={() => {
          setUpdateInProgress(true);
          handleSubmit(onSubmit)
          }}
          updateInProgress={updateInProgress}
        />
      </form>
    </div>
  );
};

type UsernameSelectionProps = {
  verificationToken: string;
  onSuccess: (username: string) => void;
};


const UsernameSelection: React.FC<UsernameSelectionProps> = ({ verificationToken, onSuccess }) => {
  type UsernameForm = {
    username: string;
  };
  const [ usernameTaken, setUsernameTaken ] = React.useState(false);
  const [ updateInProgress, setUpdateInProgress ] = React.useState(false);

  const { register, handleSubmit, errors } = useForm<UsernameForm>();
  const onSubmit = (data: UsernameForm) => {
    const { username } = data;
    setUpdateInProgress(true);
    axios
      .post('/api/auth/register-via-phone', { username, verificationToken }, getFetchParams())
      .then(() => onSuccess(username))
      .catch((e) => {
        const { error } = e.response.data;
        setUsernameTaken(error === Error.API_USERNAME_UNIQUE_MSG);
      })
      .finally(() => setUpdateInProgress(false));
  };

  return (
    <div className="signup-form">
      <div>Last step! Pick a username to continue</div>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <input
          type="text"
          placeholder="username"
          name="username"
          ref={register({
            required: true,
            validate: u => u === u.toLowerCase() || 'username must be lowercase',
          })}
        />
        {errors.username && <span className="form-error">{errors.username.message}</span>}
        {usernameTaken && <span className="form-error">{Error.USERNAME_UNAVAILABLE}</span>}
        {/* TODO add legal copy here to acknowledge terms before signing up */}
        <UpdateButton text="Create Account" handlerFunc={handleSubmit(onSubmit)} updateInProgress={updateInProgress} />
      </form>
    </div>
  );
};

const SignUp: React.FC<SignUpProps> = ({ init, loggedIn }) => {
  const { register, handleSubmit, errors } = useForm<SignupFormData>();
  const [ phone, setPhone ] = React.useState('');
  const [ codeSent, setCodeSent ] = React.useState(false);
  const [ verificationToken, setVerificationToken ] = React.useState('');
  const history = useHistory();
  const query = useQuery();

  const redirect = () => {
    const redirect = query.get('postref');
    console.log("redirecting to", redirect);
    history.push(redirect ? `/event/${redirect}` : '/');
  }

  React.useEffect(() => {
    if (loggedIn) {
      redirect();
    }
  }, [loggedIn]);

  const onAuthSuccess = (username: string) => {
    init(); // initialize a session with the new account creds
    console.log('auth success', username);
  };

  const onSubmit = (data: SignupFormData) => {
    const phone = normalizePhoneNumber(data.phone);
    setPhone(phone);
    console.log("sending code to", phone);
    axios
      .post('/api/sms/send-verification-code', { phone }, getFetchParams())
      .catch((e) => console.log(e.toJSON()));

    setCodeSent(true);
  };

  const verificationInputVisible = codeSent && !verificationToken;

  return (
    <div>
      {!codeSent && (
        <div className="signup-form">
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <div>Sign in or sign up to continue</div>
          <input
            type="text"
            disabled={codeSent}
            placeholder="phone #"
            name="phone"
            ref={register({
              required: true,
              validate: v => validatePhoneNumber(v) || 'invalid phone number format'
            })}
          />
          {errors.phone && <span className="form-error">{errors.phone.message}</span>}
          <div className="text-small">We'll send a verification code to this number</div>
            <UpdateButton text="Send Code" handlerFunc={handleSubmit(onSubmit)} />
        </form>
        </div>
      )}
      {verificationInputVisible && (<SubmitVerificationCode
        phone={phone}
        onSuccess={token => setVerificationToken(token)}
        onAuthSuccess={username => onAuthSuccess(username)}
      />)}
      {verificationToken && (
        <UsernameSelection
          verificationToken={verificationToken}
          onSuccess={username => onAuthSuccess(username)}
        />
      )}
      <div className="footer-container">
        <div>&copy; 2024 Impossible Effort Inc.</div>
      </div>

    </div>
  );
};

export default connector(SignUp);