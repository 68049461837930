import * as types from '@happenings/components/constants/actionTypes';
import {
  authFetch, beginPageFetch, receiveFeedPage,
  beginUpdate, updateSuccess, handleResponse,
} from './common_actions';

export const getPostDataAggregate = postIds => (dispatch) => {
  dispatch(beginPageFetch());
  const url = `api/posts/aggregate?postIds=${postIds.join(',')}`;
  const params = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  };
  return dispatch(authFetch(url, params))
    .then(res => dispatch(handleResponse(res, receiveFeedPage)));
};

export const beginEventUpload = () => ({
  type: types.BEGIN_EVENT_UPLOAD,
});

export const eventUploadCompleted = () => ({
  type: types.COMPLETE_EVENT_UPLOAD,
});

export const inviteUsersToPost = (postId, userIds) => (dispatch, getState) => {
  const url = `/api/posts/${postId}/invite`;
  const store = getState();
  const params = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      postId: store.entities.posts[postId].id,
      userIds,
    }),
  };
  dispatch(beginUpdate());
  dispatch(authFetch(url, params))
    .then(res => dispatch(handleResponse(res, updateSuccess)));
};
