import { connect } from 'react-redux';

import SearchResultDropdown from './SearchDropDown';
import { selectUserResults, selectPostResults } from '../../reducers/selectors';
import { updateSearchedEntity } from '../../actions/ui_actions';

const mapStateToProps = state => ({
  userResults: selectUserResults(state),
  postResults: selectPostResults(state),
  searchedEntity: state.ui.search.searchedEntity,
});

const mapDispatchToProps = dispatch => ({
  updateSearchedEntity: entity => dispatch(updateSearchedEntity(entity)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchResultDropdown);
