import { FetchAction } from "packages/components/common/types";
import Store from "packages/components/store";

export enum AttendanceType {
  UNDEFINED = 'UNDEFINED',
  GOING = 'GOING',
  NOT_GOING = 'NOT_GOING',
  MAYBE = 'MAYBE',
}

export type Attendance = {
  id: number;
  postId: number;
  username: string;
  status: AttendanceType;
}

export interface Action {
  type: string;
  postId: number;
  attendanceList: Attendance[];
}

export const conjugateAttendanceStatus = (status: AttendanceType): string => {
  const mapping: Record<AttendanceType, string> = {
    UNDEFINED: 'undefined',
    GOING: 'going',
    NOT_GOING: 'can\'t go',
    MAYBE: 'maybe',
  };
  return mapping[status];
}

export const getAttendance = (postId: number): FetchAction => ({
  type: 'GET_ATTENDANCE',
  payload: { postId },
});

export const updateAttendance = (postId: number, attendanceStatus: AttendanceType): FetchAction => ({
  type: 'CREATE_ATTENDANCE',
  payload: { postId, attendanceStatus },
});

export const toggleAttendance = (
  postId: number,
  userId: number,
  currVal: boolean
): FetchAction =>
  currVal
    ? { type: 'REMOVE_ATTENDANCE', payload: { postId, userId } }
    : updateAttendance(postId, AttendanceType.GOING);

// SELECTORS
export const currUserAttendanceStatus = (state: Store, postId: number): AttendanceType => {
  if (state.entities.attendance[postId] && !!state.session.currentUser) {
    const { username } = state.session.currentUser;
    const attendance = state.entities.attendance[postId]
      .filter((e: Attendance) => e.username === username);
    if (attendance.length > 0) {
      return attendance[0].status;
    }
  }
  return AttendanceType.UNDEFINED;
}

type State = Record<number, Attendance[]>;

const reducer = (state: State = {}, action: Action) => {
  switch (action.type) {
    case 'RECEIVE_POST_ATTENDNACE':
      return {
        ...state,
        [action.postId]: action.attendanceList,
      };
    default:
      return state;
  }
};

export default reducer;