import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getFeed } from '@happenings/components/feed';

import MainFeed from './MainFeed';

const mapStateToProps = state => ({
  postIDs: state.feed.postIDs,
  loading: state.ui.loading.postsLoading && !!state.entities.posts,
});

const mapDispatchToProps = dispatch => ({
  getMainFeed: () => dispatch(getFeed('discover')),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(MainFeed));
