import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import KeySelector from '../util/KeySelector';

const TimePicker = ({ hour, minute, amOrPm, updateFormField, isEmpty }) => {
  const [pickerOpen, setPickerOpen] = useState(false);
  const [showPlaceholder, setShowPlaceholder] = useState(isEmpty === true);

  const onClick = (newPickerState) => {
    if (pickerOpen && newPickerState === false) {
      setShowPlaceholder(false);
    }
    setPickerOpen(newPickerState);
  };

  const setSelection = fieldName => (val) => {
    if (showPlaceholder) { setShowPlaceholder(false); }
    updateFormField(fieldName, val);
  };

  const picker = (
    <div className="time-picker-container">
      <div className="hour-selection">
        HH
        <KeySelector
          containerClass="hour-selector"
          keyList={['12', '01', '02', '03']}
          btnClass="unselected-btn"
          selection={hour}
          onChange={setSelection('hour')}
        />
        <KeySelector
          containerClass="hour-selector"
          keyList={['04', '05', '06', '07']}
          btnClass="unselected-btn"
          selection={hour}
          onChange={setSelection('hour')}
        />
        <KeySelector
          containerClass="hour-selector"
          keyList={['08', '09', '10', '11']}
          btnClass="unselected-btn"
          selection={hour}
          onChange={setSelection('hour')}
        />
      </div>
      <div className="minute-selection">
        MM
        <KeySelector
          containerClass="hour-selector"
          keyList={['00', '15', '30', '45']}
          btnClass="unselected-btn"
          selection={minute}
          onChange={setSelection('minute')}
        />
      </div>
      <div className="am-pm-selection">
        <KeySelector
          containerClass="hour-selector"
          keyList={['AM', 'PM']}
          btnClass="unselected-btn"
          selection={amOrPm}
          onChange={setSelection('amOrPm')}
        />
      </div>
    </div>
  );
  const timeCls = classNames('date-field margin-bottom', { 'placeholder': showPlaceholder });
  return (
    <div>
      <div role="button" className={timeCls} onClick={() => onClick(!pickerOpen)}>
        {showPlaceholder ? 'Select a time' : `${hour}:${minute} ${amOrPm}` }
      </div>
      { pickerOpen && picker }
    </div>
  );
};

TimePicker.propTypes = {
  hour: PropTypes.string,
  minute: PropTypes.string,
  amOrPm: PropTypes.string,
  updateFormField: PropTypes.func.isRequired,
};

TimePicker.defaultProps = {
  hour: '12',
  minute: '00',
  amOrPm: 'AM',
};

export default TimePicker;
