import * as types from '../constants/actionTypes';

// modal will have some ID associated with it (i.e. which post it is)
export const closeModal = modalName => ({
  type: types.CLOSE_MODAL,
  modalName,
});

export const openModal = (modalName, value) => ({
  type: types.OPEN_MODAL,
  modalName,
  value,
});

export const clearInviteUsersSearch = () => ({
  type: types.CLEAR_INVITE_USERS_SEARCH,
});

export const updateSearchedEntity = searchedEntity => ({
  type: types.UPDATE_SEARCHED_ENTITY,
  searchedEntity,
});

export const toggleBar = () => ({
  type: types.TOGGLE_BAR,
});

export const closeSearchBar = () => ({
  type: types.CLOSE_SEARCH_BAR,
});
