export function isUrl(s) {
  // eslint-disable-next-line no-useless-escape
  const regexp = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
  return regexp.test(s);
}

// helper to flatten a dict of dicts to an array of dicts
export function toArray(dict) {
  return Object.keys(dict).map(key => ({ ...dict[key], key }));
}

export const mapEntityId = (posts, idKey = 'id') => {
  const postIdMap = {};
  posts.forEach((post) => {
    const id = post[idKey];
    postIdMap[id] = post;
  });
  return postIdMap;
};

/**
 * DATA FETCHING UTILS
 */

// for use with redux-saga select effect
export const getToken = state => state.session.currentUser.token;

export const getFetchParams = (token = undefined) => {
  const base = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  };
  if (token) {
    return {
      headers: {
        ...base, 'Authorization': `Bearer ${token}`,
      },
    };
  }
  return { headers: base };
};
