import { connect } from 'react-redux';
import { closeModal } from '../../actions/ui_actions';
import { modalTypes as MODAL_TYPES } from '../../constants/modalTypes';
import InviteModal from './InviteModal';

const mapStateToProps = state => ({
  currentUser: state.session.currentUser,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal(MODAL_TYPES.INVITE_MODAL)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InviteModal);
