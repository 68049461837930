import React from 'react';
import LegalPageHeader from './LegalPageHeader';
import html from './terms-of-service';

const TermsAndConditions = () => {
  return (
    <div className="legal-page terms-of-service">
      <LegalPageHeader title={'Terms of Service'} />
      <div className="landing-message">
      </div>
      <div className="text-content" dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  );
};

export default TermsAndConditions;
