import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Utility code for building a 'KeySelector' component like when u select a size on an online store
 * [S] [M] [L] [XL] <-- u can only select one of these buttons at a time
 *                      and the selected option is held in some parent state
 */

/**
 * Config function that returns a builder function to create 'selectable' JSX button elements.
 * The returned function is then passed as a prop to the KeySelector component.
 *
 * @param  {shape({
 * [PropTypes.string]: PropTypes.string,})} displayNames   mapping of button key to display name
 * @param  {string} btnClass       CSS classname of the button element
 * @param  {string} selectedClass  CSS classname for a selected button
 * @return {function}              the button builder function that takes 3 args
 */
const getButtonBuilder = (displayNames, btnClass, selectedClass = 'selected') => (
  (key, selection, onSelect) => (
    <div
      key={key}
      role="button"
      className={classNames(btnClass, { [selectedClass]: selection === key })}
      onClick={() => onSelect(key)}
    >
      {displayNames[key]}
    </div>
  )
);

/**
 * KeySelector: A generic class to avoid duplicated logic. hopefully this can be widely reused
 * @param  {string} options.containerClass  CSS classname for the button group
 * @param  {array} options.keyList          list of keys that identify buttons
 * @param  {string} options.selection       the selected key (parent state passed as prop)
 * @param  {function} options.buttonBuilder function to render buttons with classNames behavior
 * @param  {function} options.onChange      callback to register selected key change w/ parent state
 * @return {React Component}                Returns a component with clickable button-tab-things
 */
const KeySelector = ({ containerClass, keyList, selection, onChange, btnNameMap, btnClass, selectedClass }) => {
  let displayNames = btnNameMap;
  // if no explicit display name mapping provided,
  // create a default display mapping using the key names
  if (!btnNameMap) {
    displayNames = keyList.reduce((acc, key) => ({ ...acc, [key]: key }), {});
  }

  const buttonBuilder = getButtonBuilder(displayNames, btnClass, selectedClass);
  return (
    <div className={containerClass}>
      {
        keyList.map(k => (buttonBuilder(k, selection, onChange)))
      }
    </div>
  );
};

KeySelector.propTypes = {
  containerClass: PropTypes.string,
  keyList: PropTypes.arrayOf(PropTypes.string).isRequired,
  selection: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  btnNameMap: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }),
  btnClass: PropTypes.string.isRequired,
  selectedClass: PropTypes.string,
};

KeySelector.defaultProps = {
  selectedClass: 'selected',
  containerClass: '',
  btnNameMap: undefined,
  selection: '',
};

export default KeySelector;
