import { combineReducers } from 'redux';

import session from '@happenings/components/session';
import errors from '@happenings/components/errors';
import pagination from '@happenings/components/pagination';
import feed from '@happenings/components/feed';
import entities from '@happenings/components/reducers/entities';
import profile from "@happenings/components/profile"

import ui from './ui';

const rootReducer = combineReducers({
  session,
  errors,
  ui,
  entities,
  pagination,
  feed,
  profile,
});

export default rootReducer;
