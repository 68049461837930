import { connect } from 'react-redux';

import Search from './Search';
import { searchUsers, searchPosts, clearSearch } from '@happenings/components/search';
import { closeSearchBar } from '../../actions/ui_actions';

const mapStateToProps = state => ({
  searchedEntity: state.ui.search.searchedEntity,
  barOpen: state.ui.search.barOpen,
});

const mapDispatchToProps = dispatch => ({
  searchUsers: query => dispatch(searchUsers(query)),
  searchPosts: query => dispatch(searchPosts(query)),
  clearSearch: () => dispatch(clearSearch()),
  closeSearchBar: () => dispatch(closeSearchBar()),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Search);
