import React from 'react';
import PropTypes from 'prop-types';
import {
  TIMELINE_OPTIONS, TIMELINE_DISPLAY_NAMES } from '../../constants';

import KeySelector from '../util/KeySelector';

const TimelineFeedSelector = ({ selection, onChange }) => {
  return (
    <KeySelector
      keyList={TIMELINE_OPTIONS}
      containerClass="timeline-selector"
      btnNameMap={TIMELINE_DISPLAY_NAMES}
      btnClass="timeline-btn"
      selection={selection}
      onChange={onChange}
    />
  );
};

TimelineFeedSelector.propTypes = {
  selection: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TimelineFeedSelector;
